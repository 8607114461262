/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateProjectDto {
  name: string
  teamId: string
}

export interface DisplayImportSourceDto {
  id: string
  name: string
  description?: string
  type: 'DATABASE' | 'OTHER_SOURCE' | 'HAND_SEARCH' | 'CITATION_SEARCH' | 'FIELD_SAFETY_NOTICES'
  url?: string
  order: number
}

export interface DisplayFileDto {
  name: string
  path: string
}

export type StudyDesign = object

export type Attribute = object

export interface DisplayArticleDto {
  type: 'article' | 'guidance' | 'incident' | 'registration' | 'fieldSafetyNotice' | 'standard'
  pdfContent?: string
  pdfFile?: DisplayFileDto
  search?: DisplaySearchDto
  id: number
  parentStudyId?: number
  maybeParentStudyId?: number
  isDuplicate?: boolean
  isFavorite?: boolean
  /** @format date-time */
  complaintDate?: string
  titleAndAbstractScreening?: string
  fullTextScreening?: string
  oxfordLevelOfEvidence?: string
  peerReviewStatus?: '' | 'yes' | 'no' | 'notApplicable'
  studyDesignStatus?: 'applicable' | 'notApplicable'
  studyDesign?: StudyDesign
  synthesis: {
    attributes: Attribute[]
  }
  metadata: {
    pmid?: string
    rawData?: string
    title?: string
    abstract?: string
    authors?: string[]
    doi?: string
    url?: string
    publishYear?: string
    journal?: string
    volumeNumber?: string
    pmcId?: string
    issueNumber?: string
    pagesNumber?: string
    reference?: string
    section?: string
  }
}

export interface DisplaySearchDto {
  id: number
  personUndertakingSearch: string
  source: DisplayImportSourceDto
  searchDate?: object
  studies?: DisplayArticleDto[]
  searchQuery?: string
  url?: string
  parentStudyId: number
  queryTranslation?: string
  filters?: string
  originalStudiesCount?: string
}

export interface DisplayScreeningPlanDto {
  titleAndAbstractCriteria: string[]
  fullTextCriteria: string[]
  searches?: DisplaySearchDto[]
}

export interface DisplayImportPlanDto {
  importSources: DisplayImportSourceDto[]
}

export interface DisplayAppraisalPlanDto {
  isImdrfMdce2019Applicable: boolean
  isOxfordLevelOfEvidenceApplicable: boolean
  isPeerReviewStatusApplicable: boolean
}

export interface DisplaySynthesisAttribute {
  id: string
  label: string
  question: string
  order: string
}

export interface DisplaySynthesisPlan {
  summary: string
  attributesStructure: DisplaySynthesisAttribute[]
}

export interface DisplayInclusionCriteriaDto {
  intervention: string[]
  comparisonControlComparator: string[]
  outcomes: string[]
  studyDesign: string[]
  timing: string[]
  patient: string[]
  isPicoInclusionCriteriaApplicable?: boolean
  criteria?: string[]
}

export interface AnswerDisplayDto {
  id: string
  title: string
}

export interface AppraisalCriterionDisplayDto {
  id: string
  title?: string
  question?: string
  answers?: AnswerDisplayDto[]
}

export interface AppraisalCriteriaDisplayDto {
  criterion?: AppraisalCriterionDisplayDto
}

export interface DisplayLiteratureReviewPlanDto {
  screeningPlan?: DisplayScreeningPlanDto
  importPlan?: DisplayImportPlanDto
  appraisalPlan?: DisplayAppraisalPlanDto
  synthesisPlan?: DisplaySynthesisPlan
  inclusionCriteria?: DisplayInclusionCriteriaDto
  lockState?: 'LOCKED' | 'UNLOCKED' | 'DISABLED'
  searchStrategySummary?: string
  query?: string
  manufacturerName?: string
  alternativeDeviceNames?: string[]
  alternativeManufacturerNames?: string[]
  preset:
    | ''
    | 'MEDICAL_BACKGROUND'
    | 'GENERIC_DEVICE_GROUP'
    | 'MEDICAL_ALTERNATIVE'
    | 'DEVICE_SPECIFIC_SEARCH'
    | 'SIMILAR_DEVICE_SPECIFIC_SEARCH'
    | 'CUSTOM'
  deviceName?: string
  deviceType?: string
  purpose?: string
  problem?: string
  problemName?: string
  alternativeName?: string
  fsnCountries?: string[]
  appraisalCriteria?: AppraisalCriteriaDisplayDto[]
}

export interface DisplayUserDto {
  id: string
  email: string
  firstName: string
  lastName: string
  isDisabled: boolean
  role: 'ADMIN' | 'USER' | 'READ_ONLY'
  acceptedTermsVersion: string
}

export interface DisplayProjectDto {
  id: number
  name: string
  contactForComplaintEmail?: string
  reviews?: DisplayReviewDto[]
  users?: DisplayUserDto[]
  useReviewsPlanLocking: boolean
}

export type Evaluator = object

export interface DisplayReviewDto {
  id: number
  name: string
  plan?: DisplayLiteratureReviewPlanDto
  searches?: DisplaySearchDto[]
  project?: DisplayProjectDto
  isLocked: boolean
  isArchived: boolean
  evaluators?: Evaluator[]
}

export interface CreateMedicalBackgroundPresetDto {
  preset:
    | ''
    | 'MEDICAL_BACKGROUND'
    | 'GENERIC_DEVICE_GROUP'
    | 'MEDICAL_ALTERNATIVE'
    | 'DEVICE_SPECIFIC_SEARCH'
    | 'SIMILAR_DEVICE_SPECIFIC_SEARCH'
    | 'CUSTOM'
  problem: string
  purpose: string
  problemName: string
}

export interface CreateGenericDeviceGroupPresetDto {
  preset:
    | ''
    | 'MEDICAL_BACKGROUND'
    | 'GENERIC_DEVICE_GROUP'
    | 'MEDICAL_ALTERNATIVE'
    | 'DEVICE_SPECIFIC_SEARCH'
    | 'SIMILAR_DEVICE_SPECIFIC_SEARCH'
    | 'CUSTOM'
  alternativeName: string
}

export interface CreateMedicalAlternativePresetDto {
  preset:
    | ''
    | 'MEDICAL_BACKGROUND'
    | 'GENERIC_DEVICE_GROUP'
    | 'MEDICAL_ALTERNATIVE'
    | 'DEVICE_SPECIFIC_SEARCH'
    | 'SIMILAR_DEVICE_SPECIFIC_SEARCH'
    | 'CUSTOM'
  alternativeName: string
}

export interface CreateDeviceSpecificSearchPresetDto {
  preset:
    | ''
    | 'MEDICAL_BACKGROUND'
    | 'GENERIC_DEVICE_GROUP'
    | 'MEDICAL_ALTERNATIVE'
    | 'DEVICE_SPECIFIC_SEARCH'
    | 'SIMILAR_DEVICE_SPECIFIC_SEARCH'
    | 'CUSTOM'
  deviceName: string
  manufacturerName: string
  alternativeDeviceNames: string[]
  alternativeManufacturerNames: string[]
}

export interface CreateSimilarDeviceSpecificSearchPresetDto {
  preset:
    | ''
    | 'MEDICAL_BACKGROUND'
    | 'GENERIC_DEVICE_GROUP'
    | 'MEDICAL_ALTERNATIVE'
    | 'DEVICE_SPECIFIC_SEARCH'
    | 'SIMILAR_DEVICE_SPECIFIC_SEARCH'
    | 'CUSTOM'
  deviceName: string
  fsnCountries: string[]
  manufacturerName: string
  alternativeDeviceNames: string[]
  alternativeManufacturerNames: string[]
}

export interface CreateDefaultPresetDto {
  preset:
    | ''
    | 'MEDICAL_BACKGROUND'
    | 'GENERIC_DEVICE_GROUP'
    | 'MEDICAL_ALTERNATIVE'
    | 'DEVICE_SPECIFIC_SEARCH'
    | 'SIMILAR_DEVICE_SPECIFIC_SEARCH'
    | 'CUSTOM'
}

export interface CreateReviewDto {
  name: string
  reviewPreset:
    | CreateMedicalBackgroundPresetDto
    | CreateGenericDeviceGroupPresetDto
    | CreateMedicalAlternativePresetDto
    | CreateDeviceSpecificSearchPresetDto
    | CreateSimilarDeviceSpecificSearchPresetDto
    | CreateDefaultPresetDto
}

export interface UpdateContactForComplaintEmailDto {
  email: string
}

export interface UpdateProjectNameDto {
  name: string
}

export interface UpdateProjectCslStyleDto {
  style: string
}

export type Date = object

export interface PlanScreeningDto {
  titleAndAbstractCriteria: string[]
  fullTextCriteria: string[]
}

export interface AddCriterionDto {
  criterion: string
}

export interface UpdateInclusionCriterionDto {
  criterion: string
}

export interface UpdatePicoInclusionCriterionDto {
  criterion: string
  criterionType: 'patient' | 'intervention' | 'comparisonControlComparator' | 'outcomes' | 'studyDesign' | 'timing'
}

export interface DeleteCriterionDto {
  criterion: string
}

export interface UpdateReviewNameDto {
  name: string
}

export interface UploadFileDto {
  /** @format binary */
  file: File
}

export interface EditStudyAbstractDto {
  abstract: string
}

export interface AddImportSourceToPlanDto {
  importSourceId: string
}

export interface CreateAppraisalEntryDto {
  criterionId: string
  gradeId: string
}

export interface CreateOxfordLevelOfEvidenceEntryDto {
  oxfordLevelOfEvidence: 'I' | 'II' | 'III' | 'IV' | 'V'
}

export interface SetPeerReviewStatusDto {
  peerReviewStatus: '' | 'yes' | 'no' | 'notApplicable'
}

export interface UpdateStudyDto {
  title: string
  abstract: string
  doi: string
  publishYear: string
  journal: string
  volumeNumber: string
  issueNumber: string
  pagesNumber: string
  authors: string[]
}

export interface AddSynthesisAttributeDto {
  label: string
  question: string
  order: string
}

export interface UpdateSynthesisPlanSummaryDto {
  summary: string
}

export interface UpdateSearchStrategyDto {
  strategy: string
}

export interface CreateIncidentDto {
  type: 'article' | 'guidance' | 'incident' | 'registration' | 'fieldSafetyNotice' | 'standard'
  eventDate?: string
  eventType?: string
  reportDate?: string
  reportNumber?: string
  summary?: string
  title?: string
  url?: string
}

export interface CreateArticleDto {
  abstract?: string
  authors?: string[]
  doi?: string
  issueNumber?: string
  journal?: string
  pagesNumber?: string
  pmid?: string
  potentialPdfUrl?: string
  publishYear?: string
  rawData?: string
  reference?: string
  title?: string
  url?: string
  volumeNumber?: string
  pmcId?: string
}

export interface CreateGuidancetDto {
  type: 'article' | 'guidance' | 'incident' | 'registration' | 'fieldSafetyNotice' | 'standard'
  title?: string
  reference?: string
  publishYear?: string
  attachmentLink?: string
  section?: string
}

export interface CreateRegistrationDto {
  type: 'article' | 'guidance' | 'incident' | 'registration' | 'fieldSafetyNotice' | 'standard'
  title?: string
  url?: string
  registrationDate?: string
  registrationId?: string
}

export interface CreateFieldSafetyNoticeDto {
  type: 'article' | 'guidance' | 'incident' | 'registration' | 'fieldSafetyNotice' | 'standard'
  title?: string
  link?: string
  date?: string
  summary?: string
  fsnType?: string
  source?: string
  fulltext?: string
  fsnId?: string
  potentialPdfUrl?: string
}

export interface CreateSearchDto {
  items: (
    | CreateArticleDto
    | CreateIncidentDto
    | CreateGuidancetDto
    | CreateRegistrationDto
    | CreateFieldSafetyNoticeDto
  )[]
  date: string
  filters?: string
  query?: string
  importSourceId: string
  originalStudiesCount?: string
  queryTranslation?: string
  url?: string
}

export interface AddArticleToSearchDto {
  items: CreateArticleDto[]
}

export interface CreateCitationSearchDto {
  parentStudyId: number
}

export interface UpdatedAttributeDTO {
  label: string
  question: string
  id: string
  order: string
}

export interface UpdateStudySynthesisAttributeDTO {
  id: string
  value: string
}

export interface AddCustomImportSourceDto {
  id?: string
  name: string
  url: string
  type: 'DATABASE' | 'OTHER_SOURCE' | 'HAND_SEARCH' | 'CITATION_SEARCH' | 'FIELD_SAFETY_NOTICES'
  description?: string
}

export interface AddAppraisalCriteriaDto {
  appraisalCriterionId: string
  answer: string
}

export interface DeleteAppraisalCriterionAnswerDto {
  appraisalCriterionId: string
  answerId: string
}

export interface SetStudyDesignStatusDto {
  studyDesignStatus: 'applicable' | 'notApplicable'
}

export interface StudyDesignDto {
  countries: string[]
  sites: number
  plannedNumberOfSubjects: number
  direction: string
  type: string
  design: string
  controlled: '' | 'yes' | 'no'
  inclusionCriteria: string[]
  exclusionCriteria: string[]
  primaryOutcome: string[]
  secondaryOutcome: string[]
  participantBlinding: '' | 'yes' | 'no'
  researcherBlinding: '' | 'yes' | 'no'
  analysisBlinding: '' | 'yes' | 'no'
  followUp: '' | 'yes' | 'no'
  followUpTime: string
}

export interface CreateEvaluatorDto {
  role: string
  name: string
}

export interface UpdateEvaluatorDto {
  role: string
  name: string
}

export interface CreateUserDto {
  email: string
  firstName: string
  lastName: string
  password: string
  role: 'ADMIN' | 'USER' | 'READ_ONLY'
}

export interface UpdateUserFullNameDto {
  firstName: string
  lastName: string
}

export type User = object

export interface EditUserDto {
  userId: string
  firstName: string
  lastName: string
  role: 'ADMIN' | 'USER' | 'READ_ONLY'
}

export interface UpdateUserStateDto {
  userId: string
}

export interface ConfigureStudiesQADto {
  apiKey: string
}

export interface AnswerStudyQuestionDto {
  question: string
}

export interface UpdateAssistantModelDto {
  model: string
}

export interface CreateTeamUsecaseDto {
  name: string
}

export interface DisplayTeamDto {
  id: string
  name: string
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Systematic review Service
 * @version 1.0.0
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerCreate
     * @request POST:/api/projects
     */
    projectsControllerCreate: (data: CreateProjectDto, params: RequestParams = {}) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/projects`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerFind
     * @request GET:/api/projects
     */
    projectsControllerFind: (params: RequestParams = {}) =>
      this.request<DisplayProjectDto[], any>({
        path: `/api/projects`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerFindById
     * @request GET:/api/projects/{projectId}
     */
    projectsControllerFindById: (projectId: number, params: RequestParams = {}) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/projects/${projectId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerDelete
     * @request DELETE:/api/projects/{projectId}
     */
    projectsControllerDelete: (projectId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerCreateReview
     * @request POST:/api/projects/{id}/review
     */
    projectsControllerCreateReview: (id: number, data: CreateReviewDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/projects/${id}/review`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerExportStudiesZip
     * @request GET:/api/projects/{projectId}/pdf/{selectedReviewsIds}/studies/zip
     */
    projectsControllerExportStudiesZip: (projectId: number, selectedReviewsIds: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/pdf/${selectedReviewsIds}/studies/zip`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerUpdateContactForComplaintEmail
     * @request PATCH:/api/projects/{projectId}/contact-for-complaint-email
     */
    projectsControllerUpdateContactForComplaintEmail: (
      projectId: number,
      data: UpdateContactForComplaintEmailDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/contact-for-complaint-email`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerUpdateName
     * @request PATCH:/api/projects/{projectId}/name
     */
    projectsControllerUpdateName: (projectId: number, data: UpdateProjectNameDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/name`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerUpdateCslStyle
     * @request PATCH:/api/projects/{projectId}/csl-style
     */
    projectsControllerUpdateCslStyle: (projectId: number, data: UpdateProjectCslStyleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/csl-style`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerSetReviewsPlanLocking
     * @request PATCH:/api/projects/{projectId}/set-reviews-plan-locking
     */
    projectsControllerSetReviewsPlanLocking: (
      projectId: number,
      query: {
        useReviewPlanLocking: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/set-reviews-plan-locking`,
        method: 'PATCH',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerAddMember
     * @request PUT:/api/projects/{projectId}/users/{userId}
     */
    projectsControllerAddMember: (projectId: number, userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/users/${userId}`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerRemoveMember
     * @request DELETE:/api/projects/{projectId}/users/{userId}
     */
    projectsControllerRemoveMember: (projectId: number, userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/users/${userId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFindById
     * @request GET:/api/Reviews/{reviewId}
     */
    reviewsControllerFindById: (
      reviewId: number,
      query: {
        shouldIncludeSearches: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<DisplayReviewDto, any>({
        path: `/api/Reviews/${reviewId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDelete
     * @request DELETE:/api/Reviews/{selectedReviewsIds}
     */
    reviewsControllerDelete: (selectedReviewsIds: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${selectedReviewsIds}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSubmitComplaint
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/submit-complaint
     */
    reviewsControllerSubmitComplaint: (
      reviewId: number,
      studyId: number,
      data: StudyDesign,
      params: RequestParams = {},
    ) =>
      this.request<Date, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/submit-complaint`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerMarkStudyAsDuplicate
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/mark-as-duplicate
     */
    reviewsControllerMarkStudyAsDuplicate: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/mark-as-duplicate`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerMarkStudyAsNotDuplicate
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/mark-as-not-duplicate
     */
    reviewsControllerMarkStudyAsNotDuplicate: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/mark-as-not-duplicate`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSetStudyTitleAndAbstractScreening
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/title-and-abstract-screening
     */
    reviewsControllerSetStudyTitleAndAbstractScreening: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/title-and-abstract-screening`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyTitleAndAbstractScreening
     * @request DELETE:/api/Reviews/{reviewId}/studies/{studyId}/title-and-abstract-screening
     */
    reviewsControllerClearStudyTitleAndAbstractScreening: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/title-and-abstract-screening`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSetStudyFullTextScreening
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/full-text-screening
     */
    reviewsControllerSetStudyFullTextScreening: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/full-text-screening`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyFullTextScreening
     * @request DELETE:/api/Reviews/{reviewId}/studies/{studyId}/full-text-screening
     */
    reviewsControllerClearStudyFullTextScreening: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/full-text-screening`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteSearch
     * @request DELETE:/api/Reviews/{reviewId}/search/{searchId}
     */
    reviewsControllerDeleteSearch: (reviewId: number, searchId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/search/${searchId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerPlanScreening
     * @request PUT:/api/Reviews/{reviewId}/plan-screening
     */
    reviewsControllerPlanScreening: (reviewId: number, data: PlanScreeningDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/plan-screening`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerPlanLocking
     * @request PUT:/api/Reviews/{reviewId}/plan-locking
     */
    reviewsControllerPlanLocking: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/plan-locking`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerPlanUnlocking
     * @request PUT:/api/Reviews/{reviewId}/plan-unlocking
     */
    reviewsControllerPlanUnlocking: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/plan-unlocking`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddFullTextCriterion
     * @request PUT:/api/Reviews/{reviewId}/add-full-text-criterion
     */
    reviewsControllerAddFullTextCriterion: (reviewId: number, data: AddCriterionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/add-full-text-criterion`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddInclusionluCriterion
     * @request PUT:/api/Reviews/{reviewId}/add-inclusion-criterion
     */
    reviewsControllerAddInclusionluCriterion: (
      reviewId: number,
      data: UpdateInclusionCriterionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/add-inclusion-criterion`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddPicoInclusionluCriterion
     * @request PUT:/api/Reviews/{reviewId}/add-pico-inclusion-criterion
     */
    reviewsControllerAddPicoInclusionluCriterion: (
      reviewId: number,
      data: UpdatePicoInclusionCriterionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/add-pico-inclusion-criterion`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteFullTextCriterion
     * @request PUT:/api/Reviews/{reviewId}/delete-full-text-criterion
     */
    reviewsControllerDeleteFullTextCriterion: (reviewId: number, data: AddCriterionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/delete-full-text-criterion`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteInclusionCriterion
     * @request PUT:/api/Reviews/{reviewId}/delete-inclusion-criterion
     */
    reviewsControllerDeleteInclusionCriterion: (
      reviewId: number,
      data: UpdateInclusionCriterionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/delete-inclusion-criterion`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeletePicoInclusionCriterion
     * @request PUT:/api/Reviews/{reviewId}/delete-pico-inclusion-criterion
     */
    reviewsControllerDeletePicoInclusionCriterion: (
      reviewId: number,
      data: UpdatePicoInclusionCriterionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/delete-pico-inclusion-criterion`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddTitleAndAbstractCriterion
     * @request PUT:/api/Reviews/{reviewId}/add-title-and-abstract-criterion
     */
    reviewsControllerAddTitleAndAbstractCriterion: (
      reviewId: number,
      data: AddCriterionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/add-title-and-abstract-criterion`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteTitleAndAbstractCriterion
     * @request PUT:/api/Reviews/{reviewId}/delete-title-and-abstract-criterion
     */
    reviewsControllerDeleteTitleAndAbstractCriterion: (
      reviewId: number,
      data: DeleteCriterionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/delete-title-and-abstract-criterion`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEnableTitleAndAbstractScreening
     * @request PATCH:/api/Reviews/{reviewId}/enable-title-and-abstract-screening
     */
    reviewsControllerEnableTitleAndAbstractScreening: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/enable-title-and-abstract-screening`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDisableTitleAndAbstractScreening
     * @request PATCH:/api/Reviews/{reviewId}/disable-title-and-abstract-screening
     */
    reviewsControllerDisableTitleAndAbstractScreening: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/disable-title-and-abstract-screening`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFindProject
     * @request GET:/api/Reviews/{reviewId}/project
     */
    reviewsControllerFindProject: (reviewId: number, params: RequestParams = {}) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/Reviews/${reviewId}/project`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFavoriteStudy
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/favorite
     */
    reviewsControllerFavoriteStudy: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/favorite`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUnfavoriteStudy
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/unfavorite
     */
    reviewsControllerUnfavoriteStudy: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/unfavorite`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateName
     * @request PATCH:/api/Reviews/{reviewId}/name
     */
    reviewsControllerUpdateName: (reviewId: number, data: UpdateReviewNameDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/name`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUploadStudyPdfFile
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/upload-pdf
     */
    reviewsControllerUploadStudyPdfFile: (
      reviewId: number,
      studyId: number,
      data: UploadFileDto,
      params: RequestParams = {},
    ) =>
      this.request<DisplayFileDto, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/upload-pdf`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerGetStudyPdfFile
     * @request GET:/api/Reviews/{reviewId}/studies/{studyId}/pdf
     */
    reviewsControllerGetStudyPdfFile: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/pdf`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerExportStudiesZip
     * @request GET:/api/Reviews/{reviewId}/studies/zip
     */
    reviewsControllerExportStudiesZip: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/zip`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteStudyPdfFile
     * @request DELETE:/api/Reviews/{reviewId}/studies/{studyId}/pdf-file
     */
    reviewsControllerDeleteStudyPdfFile: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/pdf-file`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEditStudyAbstract
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/abstract
     */
    reviewsControllerEditStudyAbstract: (
      reviewId: number,
      studyId: number,
      data: EditStudyAbstractDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/abstract`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerRemoveImportSourceFromPlan
     * @request DELETE:/api/Reviews/{reviewId}/import-plan/import-source
     */
    reviewsControllerRemoveImportSourceFromPlan: (
      reviewId: number,
      data: AddImportSourceToPlanDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/import-plan/import-source`,
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEnableStudiesAppraisalImdrfMdce2019
     * @request PATCH:/api/Reviews/{reviewId}/enable-studies-appraisal
     */
    reviewsControllerEnableStudiesAppraisalImdrfMdce2019: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/enable-studies-appraisal`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDisableStudiesAppraisal
     * @request PATCH:/api/Reviews/{reviewId}/disable-studies-appraisal
     */
    reviewsControllerDisableStudiesAppraisal: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/disable-studies-appraisal`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEnableStudiesAppraisalOxfordLevelOfEvidence
     * @request PATCH:/api/Reviews/{reviewId}/enable-studies-appraisal-oxford-level-of-evidence
     */
    reviewsControllerEnableStudiesAppraisalOxfordLevelOfEvidence: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/enable-studies-appraisal-oxford-level-of-evidence`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEnableStudiesAppraisalPeerReviewStatus
     * @request PATCH:/api/Reviews/{reviewId}/enable-studies-appraisal-peer-review-status
     */
    reviewsControllerEnableStudiesAppraisalPeerReviewStatus: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/enable-studies-appraisal-peer-review-status`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDisableStudiesAppraisalPeerReviewStatus
     * @request PATCH:/api/Reviews/{reviewId}/disable-studies-appraisal-peer-review-status
     */
    reviewsControllerDisableStudiesAppraisalPeerReviewStatus: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/disable-studies-appraisal-peer-review-status`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDisableStudiesAppraisalOxfordLevelOfEvidence
     * @request PATCH:/api/Reviews/{reviewId}/disable-studies-appraisal-oxford-level-of-evidence
     */
    reviewsControllerDisableStudiesAppraisalOxfordLevelOfEvidence: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/disable-studies-appraisal-oxford-level-of-evidence`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAppraiseStudy
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/appraise
     */
    reviewsControllerAppraiseStudy: (
      reviewId: number,
      studyId: number,
      data: CreateAppraisalEntryDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/appraise`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSetOxfordLevelOfEvidenceStudy
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/setOxfordLevelOfEvidence
     */
    reviewsControllerSetOxfordLevelOfEvidenceStudy: (
      reviewId: number,
      studyId: number,
      data: CreateOxfordLevelOfEvidenceEntryDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/setOxfordLevelOfEvidence`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSetPeerReviewStatus
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/setPeerReviewStatus
     */
    reviewsControllerSetPeerReviewStatus: (
      reviewId: number,
      studyId: number,
      data: SetPeerReviewStatusDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/setPeerReviewStatus`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyOxfordLevelOfEvidence
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/resetOxfordLevelOfEvidence
     */
    reviewsControllerClearStudyOxfordLevelOfEvidence: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/resetOxfordLevelOfEvidence`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyPeerReviewStatus
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/clearStudyPeerReviewStatus
     */
    reviewsControllerClearStudyPeerReviewStatus: (reviewId: number, studyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/clearStudyPeerReviewStatus`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyAppraisal
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/clearAppraisal/{criterionId}
     */
    reviewsControllerClearStudyAppraisal: (
      reviewId: number,
      studyId: number,
      criterionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/clearAppraisal/${criterionId}`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateStudy
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/update
     */
    reviewsControllerUpdateStudy: (
      reviewId: number,
      studyId: number,
      data: UpdateStudyDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/update`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddAttributeStructure
     * @request POST:/api/Reviews/{reviewId}/add-data-extraction-attribute
     */
    reviewsControllerAddAttributeStructure: (
      reviewId: number,
      data: AddSynthesisAttributeDto,
      params: RequestParams = {},
    ) =>
      this.request<DisplaySynthesisAttribute, any>({
        path: `/api/Reviews/${reviewId}/add-data-extraction-attribute`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateSynthesisPlanSummary
     * @request POST:/api/Reviews/{reviewId}/update-synthesis-plan-summary
     */
    reviewsControllerUpdateSynthesisPlanSummary: (
      reviewId: number,
      data: UpdateSynthesisPlanSummaryDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/update-synthesis-plan-summary`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateSearchStrategySummary
     * @request POST:/api/Reviews/{reviewId}/update-search-strategy-summary
     */
    reviewsControllerUpdateSearchStrategySummary: (
      reviewId: number,
      data: UpdateSearchStrategyDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/update-search-strategy-summary`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerImportSearch
     * @request POST:/api/Reviews/{reviewId}/import-search
     */
    reviewsControllerImportSearch: (reviewId: number, data: CreateSearchDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/Reviews/${reviewId}/import-search`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddArticlesToSearch
     * @request POST:/api/Reviews/{reviewId}/searches/{searchId}/add-studies
     */
    reviewsControllerAddArticlesToSearch: (
      reviewId: number,
      searchId: number,
      data: AddArticleToSearchDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/searches/${searchId}/add-studies`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerImportCitationSearch
     * @request POST:/api/Reviews/{reviewId}/import-citation-search
     */
    reviewsControllerImportCitationSearch: (
      reviewId: number,
      data: CreateCitationSearchDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/import-citation-search`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteAttributeStructure
     * @request DELETE:/api/Reviews/{reviewId}/{attributeStructureId}/attribute-structure
     */
    reviewsControllerDeleteAttributeStructure: (
      reviewId: number,
      attributeStructureId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/${attributeStructureId}/attribute-structure`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEditAttributeStructure
     * @request PUT:/api/Reviews/{reviewId}/edit-attribute-structure
     */
    reviewsControllerEditAttributeStructure: (
      reviewId: number,
      data: UpdatedAttributeDTO,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/edit-attribute-structure`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateStudySynthesisAttribute
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/update-study-synthesis
     */
    reviewsControllerUpdateStudySynthesisAttribute: (
      reviewId: number,
      studyId: number,
      data: UpdateStudySynthesisAttributeDTO,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/update-study-synthesis`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddCustomImportSource
     * @request POST:/api/Reviews/{reviewId}/plan/custom-import-source
     */
    reviewsControllerAddCustomImportSource: (
      reviewId: number,
      data: AddCustomImportSourceDto,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/Reviews/${reviewId}/plan/custom-import-source`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFindRunningStudiesPdfImports
     * @request GET:/api/Reviews/{reviewId}/study-pdf-import-jobs
     */
    reviewsControllerFindRunningStudiesPdfImports: (reviewId: number, params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/api/Reviews/${reviewId}/study-pdf-import-jobs`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddAppraisalCriteria
     * @request POST:/api/Reviews/{reviewId}/add-appraisal-criteria
     */
    reviewsControllerAddAppraisalCriteria: (
      reviewId: number,
      data: AddAppraisalCriteriaDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/add-appraisal-criteria`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteAppraisalCriterionAnswer
     * @request PUT:/api/Reviews/{reviewId}/delete-appraisal-criterion-answer
     */
    reviewsControllerDeleteAppraisalCriterionAnswer: (
      reviewId: number,
      data: DeleteAppraisalCriterionAnswerDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/delete-appraisal-criterion-answer`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerLockReview
     * @request PATCH:/api/Reviews/{reviewId}/lock
     */
    reviewsControllerLockReview: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/lock`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUnlockReview
     * @request PATCH:/api/Reviews/{reviewId}/unlock
     */
    reviewsControllerUnlockReview: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/unlock`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerArchiveReview
     * @request PATCH:/api/Reviews/{reviewId}/archive
     */
    reviewsControllerArchiveReview: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/archive`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUnarchiveReview
     * @request PATCH:/api/Reviews/{reviewId}/unarchive
     */
    reviewsControllerUnarchiveReview: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/unarchive`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSetStudyDesignStatus
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/setStudyDesignStatus
     */
    reviewsControllerSetStudyDesignStatus: (
      reviewId: number,
      studyId: number,
      data: SetStudyDesignStatusDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/setStudyDesignStatus`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateStudyDesign
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/updateStudyDesign
     */
    reviewsControllerUpdateStudyDesign: (
      reviewId: number,
      studyId: number,
      data: StudyDesignDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/updateStudyDesign`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEnablePicoInclusionCriteria
     * @request PATCH:/api/Reviews/{reviewId}/enablePicoInclusionCriteria
     */
    reviewsControllerEnablePicoInclusionCriteria: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/enablePicoInclusionCriteria`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDisablePicoInclusionCriteria
     * @request PATCH:/api/Reviews/{reviewId}/disablePicoInclusionCriteriaUsecase
     */
    reviewsControllerDisablePicoInclusionCriteria: (reviewId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/disablePicoInclusionCriteriaUsecase`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerCreateEvaluator
     * @request POST:/api/Reviews/{reviewId}/create-evaluator
     */
    reviewsControllerCreateEvaluator: (reviewId: number, data: CreateEvaluatorDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/Reviews/${reviewId}/create-evaluator`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteEvaluator
     * @request DELETE:/api/Reviews/{reviewId}/evaluator/{evaluatorId}
     */
    reviewsControllerDeleteEvaluator: (reviewId: number, evaluatorId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/evaluator/${evaluatorId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateEvaluator
     * @request PUT:/api/Reviews/{reviewId}/evaluator/{evaluatorId}
     */
    reviewsControllerUpdateEvaluator: (
      reviewId: number,
      evaluatorId: number,
      data: UpdateEvaluatorDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/evaluator/${evaluatorId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerCreate
     * @request POST:/api/users
     */
    usersControllerCreate: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<DisplayUserDto, any>({
        path: `/api/users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerFind
     * @request GET:/api/users
     */
    usersControllerFind: (params: RequestParams = {}) =>
      this.request<DisplayUserDto[], any>({
        path: `/api/users`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerFindCurrentUser
     * @request GET:/api/users/me
     */
    usersControllerFindCurrentUser: (params: RequestParams = {}) =>
      this.request<DisplayUserDto, any>({
        path: `/api/users/me`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUpdateCurrentUserFullName
     * @request PATCH:/api/users/updateFullName
     */
    usersControllerUpdateCurrentUserFullName: (data: UpdateUserFullNameDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/updateFullName`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerEditUser
     * @request PATCH:/api/users/edit
     */
    usersControllerEditUser: (data: EditUserDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/edit`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerEnableUser
     * @request PATCH:/api/users/enableUser
     */
    usersControllerEnableUser: (data: UpdateUserStateDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/enableUser`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerDisableUser
     * @request PATCH:/api/users/disableUser
     */
    usersControllerDisableUser: (data: UpdateUserStateDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/disableUser`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetRecoveryLink
     * @request POST:/api/users/get-recovery-link
     */
    usersControllerGetRecoveryLink: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/users/get-recovery-link`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerAcceptTerms
     * @request POST:/api/users/me/accept-terms/{version}
     */
    usersControllerAcceptTerms: (version: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/me/accept-terms/${version}`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CslStyles
     * @name CslStylesControllerFindCslStyles
     * @request GET:/api/csl-styles
     */
    cslStylesControllerFindCslStyles: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/csl-styles`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthControllerCheck
     * @request GET:/api/health
     */
    healthControllerCheck: (params: RequestParams = {}) =>
      this.request<
        {
          /** @example "ok" */
          status?: string
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
          /** @example {} */
          error?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
          /** @example {"database":{"status":"up"}} */
          details?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
        },
        {
          /** @example "error" */
          status?: string
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
          /** @example {"redis":{"status":"down","message":"Could not connect"}} */
          error?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
          /** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} */
          details?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
        }
      >({
        path: `/api/health`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Studies
     * @name StudiesControllerConfigureStudiesQa
     * @request POST:/api/Studies/configure-study-qa
     */
    studiesControllerConfigureStudiesQa: (data: ConfigureStudiesQADto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Studies/configure-study-qa`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Studies
     * @name StudiesControllerAnswerStudyQuestion
     * @request POST:/api/Studies/{studyId}/answer-question
     */
    studiesControllerAnswerStudyQuestion: (studyId: number, data: AnswerStudyQuestionDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Studies/${studyId}/answer-question`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsControllerGetOpenAiKey
     * @request GET:/api/Settings/settings/open-ai-key
     */
    settingsControllerGetOpenAiKey: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Settings/settings/open-ai-key`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsControllerGetAssistant
     * @request GET:/api/Settings/settings/data-extraction-assistant
     */
    settingsControllerGetAssistant: (params: RequestParams = {}) =>
      this.request<StudyDesign, any>({
        path: `/api/Settings/settings/data-extraction-assistant`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsControllerUpdateAssistantModel
     * @request PUT:/api/Settings/settings/assistant-model
     */
    settingsControllerUpdateAssistantModel: (data: UpdateAssistantModelDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Settings/settings/assistant-model`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name TeamsControllerCreateTeam
     * @request POST:/api/teams
     */
    teamsControllerCreateTeam: (data: CreateTeamUsecaseDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/teams`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name TeamsControllerFindTeams
     * @request GET:/api/teams
     */
    teamsControllerFindTeams: (params: RequestParams = {}) =>
      this.request<DisplayTeamDto[], any>({
        path: `/api/teams`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
}
