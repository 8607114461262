<template>
  <div class="flex border-b">
    <template v-for="(tab, index) in tabs" :key="'tab-' + tab.number">
      <button
        :disabled="tab.isDisabled"
        class="last:border-r border-slate-300"
        @click="onClick(tab)"
      >
        <div
          class="group/tab whitespace-nowrap relative bg-white"
          :class="[tab.isDisabled ? 'cursor-not-allowed' : 'text-slate-600']"
        >
          <div
            class="absolute top-0 left-0 w-full h-[6px] bg-slate-200"
            :class="{ '!bg-primary': tab.number === activeTabNumber }"
          />
          <slot :tab="tab">
            <div class="flex items-center px-4 py-3">
              <div
                :class="[
                  'w-10 h-10 flex items-center justify-center shrink-0 rounded-full border text-sm font-medium leading-4',
                  tab.number === activeTabNumber
                    ? 'bg-primary text-white border-primary'
                    : 'bg-white border-slate-300 text-slate-500',
                ]"
              >
                <template v-if="tab.number === activeTabNumber">
                  <CheckIcon class="text-white w-5 h-5" />
                </template>
                <template v-else>{{ tab.number }}</template>
              </div>

              <div class="flex items-start flex-col px-6 py-3">
                <div class="text-slate-600 text-base font-medium leading-5">
                  {{ tab.title }}
                </div>
                <div
                  v-if="tab.subtitle"
                  class="text-slate-400 text-sm font-medium leading-5"
                >
                  {{ tab.subtitle }}
                </div>
              </div>
            </div>
          </slot>
          <template v-if="index !== 0">
            <div
              class="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
              aria-hidden="true"
            >
              <svg
                class="size-full text-gray-300"
                viewBox="0 0 12 82"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0.5 0V31L10.5 41L0.5 51V82"
                  stroke="currentcolor"
                  vector-effect="non-scaling-stroke"
                />
              </svg>
            </div>
          </template>
        </div>
      </button>
    </template>
  </div>
</template>
<script setup lang="ts">
import { CheckIcon } from '@heroicons/vue/24/outline'
type Tab = {
  number: number
  title?: string
  subtitle?: string
  isDisabled?: boolean
}
defineProps<{
  tabs: Tab[]
  activeTabNumber: number
}>()
function onClick(tab: Tab) {
  emit('tab:clicked', tab)
}
const emit = defineEmits<(e: 'tab:clicked', tab: Tab) => void>()
</script>
