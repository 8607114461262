<template>
  <Tabs :tabs="tabs" :style="2">
    <template #tab-content-1>
      <GeneralUserSettings />
    </template>
    <template #tab-content-2>
      <SecurityUserSettings />
    </template>
  </Tabs>
</template>
<script setup lang="ts">
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import GeneralUserSettings from './General.vue'
import SecurityUserSettings from './Security.vue'
const tabs = [
  {
    number: 1,
    title: 'General',
  },
  {
    number: 2,
    title: 'Security',
  },
]
</script>
