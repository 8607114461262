<template>
  <div class="w-screen max-w-[644px] p-6 relative pt-8">
    <button class="absolute top-3 right-3" @click="emit('hide')">
      <XMarkIcon class="w-4 h-4 text-slate-400" />
    </button>
    <div class="space-y-4">
      <div class="flex gap-1">
        <HandSearchIcon class="text-blue-800" />
        <div class="text-blue-800 font-semibold text-base">Hand search</div>
      </div>
      <div class="space-y-2 pb-4">
        <RisDropZone
          ref="risDropZoneComponent"
          v-model="citationFiles"
          v-model:error="citationFilesError"
          is-required
        />
      </div>
    </div>

    <button
      :disabled="!!citationFilesError || citationFiles.length === 0"
      :class="{
        '!bg-black/5 !text-black/40':
          !!citationFilesError || citationFiles.length === 0,
      }"
      class="w-full flex text-base gap-1 items-center justify-center bg-blue-800 text-white rounded-lg px-4 py-2 font-medium text-center"
      @click="
        importRisStudies({
          files: citationFiles,
          sourceId: BuiltInImportSourceId.HAND_SEARCH,
        })
      "
    >
      <ImportIcon /> Import
    </button>
  </div>
</template>

<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { SnackbarState } from '@app/types'
import { ref } from 'vue'
import { format } from 'date-fns'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import RisDropZone from './RisDropZone.vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import HandSearchIcon from '@app/components/Icons/HandSearchIcon.vue'
import ImportIcon from '@app/components/Icons/ImportIcon.vue'

const citationFiles = ref<{ isValid: boolean; file: File }[]>([])
const citationFilesError = ref('')
const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)

const emit = defineEmits<(e: 'hide') => void>()

async function importRisStudies({
  files,
  sourceId,
}: {
  files: { isValid: boolean; file: File }[]
  sourceId: string
}) {
  if (files.length === 0 || files.some((cf) => !cf.isValid)) {
    throw new Error('Missing or incorrect data')
  }
  loading.start()
  try {
    const dataSource = review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === sourceId,
    )
    if (!dataSource) {
      throw new Error('import source does not exist')
    }
    await review.importRisSearch({
      citationFiles: files.map((cf) => cf.file),
      date: format(new Date(), 'yyyy-MM-dd'),
      importSourceId: sourceId,
    })
    reset()
    await review.refresh()
    snackbar.show(SnackbarState.SUCCESS, 'Studies imported successfully')
  } catch (e) {
    snackbar.show(SnackbarState.ERROR, 'Import failed, please try again')
    throw e
  } finally {
    loading.stop()
  }
}

function reset() {
  citationFiles.value = []
}
</script>
