import {
  AlignmentType,
  Bookmark,
  BorderStyle,
  Document,
  ExternalHyperlink,
  FootnoteReferenceRun,
  Header,
  HeadingLevel,
  ImageRun,
  InternalHyperlink,
  ISectionOptions,
  IShadingAttributesProperties,
  ITableWidthProperties,
  LevelFormat,
  Packer,
  PageNumber,
  PageOrientation,
  Paragraph,
  ParagraphChild,
  SectionType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { StudyState } from '@app/types'
import { Review } from '@core/domain/models/review.model'
import { AppraisalCriterion } from '@core/domain/models/appraisal-criterion.model'
import { generateReviewSearchsAndLimtsResults } from './generate-search-terms-limits-result'
import {
  generateReviewScreeningResults,
  SearchScreeningResult,
} from './generate-review-screening-result'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { InclusionCriteria } from '@core/domain/models/inclusion-criteria.model'
import { generateCitation } from './generate-citations'
import { Id } from '@core/domain/types/id.type'
import { PeerReviewStatus } from '@core/domain/types/peerReview.type'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { Search } from '@core/domain/models/search.model'
import { convertHtmlToDocxParagraphs } from './generateParagraphFromHtml'
import { Evaluator } from '@core/domain/models/evaluator.model'

let tables: string[] = []
let figures: string[] = []
let tableIndex = () => 0

type StudyEntry = {
  studyId: number
  inText: string
  fullText: string
}

type GenerateProtocolOptions = {
  review: Review
  appraisalCriteria: AppraisalCriterion[]
  attributesStructure: AttributeStructure[]
  prismaDiagramImage: string
  inclusionCriteria: InclusionCriteria
  isPlanIncluded: boolean
  isReportIncluded: boolean
  dataExtraction?: any
}

export async function generateProtocol(
  options: GenerateProtocolOptions,
): Promise<Blob> {
  const {
    review,
    appraisalCriteria,
    attributesStructure,
    prismaDiagramImage,
    inclusionCriteria,
    isPlanIncluded,
    isReportIncluded,
    dataExtraction,
  } = options

  const createTableIndexing = () => {
    let tableNumber = 0
    return () => {
      tableNumber++
      return tableNumber
    }
  }
  tables = []
  figures = []
  tableIndex = createTableIndexing()

  if (!review) throw new Error('review not set')

  const includedStudies: ReviewItem[] = review.studies.filter(
    (s) => s.state === StudyState.INCLUDED,
  )
  const scholarlyArticles = review.studies.filter(
    (s) => s.type === ReviewItemType.Article,
  )

  const citations = await generateCitation(
    scholarlyArticles,
    review.project!.cslStyle!,
  )

  function mapStudies(
    type: ReviewItemType,
    getInText: (study: any) => string,
    getFullText: (study: any) => string,
  ): StudyEntry[] {
    return review.studies
      .filter((s) => s.type === type)
      .map((s) => ({
        studyId: s.id,
        inText: getInText(s),
        fullText: getFullText(s),
      }))
  }

  const studiesByType: Record<ReviewItemType, StudyEntry[]> = {
    [ReviewItemType.FieldSafetyNotice]: mapStudies(
      ReviewItemType.FieldSafetyNotice,

      (r) => (r.metadata.fsnId ? 'Fsn-id - ' + r.metadata.fsnId : 'ref'),
      (r) => r.metadata.title ?? '-',
    ),

    [ReviewItemType.Article]: mapStudies(
      ReviewItemType.Article,
      (a) => citations.findByStudyId(a.id)?.inText ?? 'ref',
      (a) => citations.findByStudyId(a.id)?.fullText ?? '-',
    ),

    [ReviewItemType.Incident]: mapStudies(
      ReviewItemType.Incident,
      (i) =>
        i.metadata.reportNumber ? 'Maude - ' + i.metadata.reportNumber : 'ref',
      (i) => i.metadata.title ?? '-',
    ),

    [ReviewItemType.Registration]: mapStudies(
      ReviewItemType.Registration,
      (r) => r.metadata.registrationId ?? 'ref',
      (r) => r.metadata.title ?? '-',
    ),

    [ReviewItemType.Guidance]: (() => {
      const frequencyMap: Record<string, number> = {}
      const studies = review.studies.filter(
        (s) => s.type === ReviewItemType.Guidance,
      )

      studies.forEach((g) => {
        const inText = g.metadata.section
          ? `MDCG - ${g.metadata.section} ${g.metadata.publishYear}`
          : 'ref'
        frequencyMap[inText] = (frequencyMap[inText] || 0) + 1
      })

      Object.keys(frequencyMap).forEach((key) => {
        if (frequencyMap[key] === 1) {
          frequencyMap[key] = -1
        }
      })

      return studies.map((g) => {
        const baseInText = g.metadata.section
          ? `MDCG - ${g.metadata.section} ${g.metadata.publishYear}`
          : 'ref'

        let uniqueInText = baseInText
        if (frequencyMap[baseInText] > 0) {
          const occurrenceIndex = frequencyMap[baseInText] - 1
          uniqueInText = `${baseInText}${String.fromCharCode(97 + occurrenceIndex)}`
          frequencyMap[baseInText]--
        }

        return {
          studyId: g.id,
          inText: uniqueInText,
          fullText: g.metadata.title ?? '-',
        }
      })
    })(),

    [ReviewItemType.Standard]: [],
    [ReviewItemType.default]: [],
  }

  const referencesSection = buildReferencesSection(studiesByType)

  let literatureSearchReportPlanText = 'Literature search '

  if (isPlanIncluded && isReportIncluded)
    literatureSearchReportPlanText += 'plan and report'
  else if (isPlanIncluded) literatureSearchReportPlanText += 'plan'
  else if (isReportIncluded) literatureSearchReportPlanText += 'report'

  const doc = new Document({
    numbering: {
      config: [
        {
          reference: 'headings',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              alignment: AlignmentType.LEFT,
              text: '%1',
            },
            {
              level: 1,
              format: LevelFormat.DECIMAL,
              alignment: AlignmentType.LEFT,
              text: '%1.%2',
            },
            {
              level: 2,
              format: LevelFormat.DECIMAL,
              alignment: AlignmentType.LEFT,
              text: '%1.%2.%3',
            },
          ],
        },
        {
          reference: 'orderedNumbering',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              alignment: AlignmentType.LEFT,
            },
          ],
        },
      ],
    },
    footnotes: {
      1: {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: 'IMDRF MDCE WG/N56FINAL:2019 (formerly GHTF/SG5/N2R8:2007)',
                size: 18,
              }),
            ],
          }),
        ],
      },
      2: {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: 'CLINICAL EVALUATION: A GUIDE FOR MANUFACTURERS AND NOTIFIED BODIES UNDER DIRECTIVES 93/42/EEC and 90/385/EEC',
                size: 18,
              }),
            ],
          }),
        ],
      },
      3: {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: 'OCEBM Levels of Evidence Working Group. “The Oxford Levels of Evidence 2”',
                size: 18,
              }),
            ],
          }),
        ],
      },
    },
    styles: {
      paragraphStyles: [
        {
          id: 'Caption',
          name: 'Caption',
          basedOn: 'Caption',
          quickFormat: true,
          paragraph: {
            spacing: {
              before: 120,
            },
          },
          run: { size: 18, bold: true },
        },
        {
          id: 'TableFooter',
          name: 'TableFooter',
          basedOn: 'Normal',
          quickFormat: true,
          run: { size: 18 },
        },
        {
          id: 'tableCell',
          name: 'tableCell',
          quickFormat: true,
          paragraph: {
            spacing: {
              before: 0,
            },
          },
          run: { size: 20 },
        },
      ],
      default: {
        title: {
          run: {
            size: 28,
            bold: true,
            color: '7F7F7F',
          },
        },
        heading1: {
          paragraph: {
            spacing: {
              before: 240,
            },
            numbering: { reference: 'headings', level: 0 },
          },
          run: {
            size: 28,
            bold: true,
            color: '000000',
          },
        },
        heading2: {
          paragraph: {
            spacing: {
              before: 120,
            },
            numbering: { reference: 'headings', level: 1 },
          },
          run: {
            size: 24,
            bold: true,
            color: '000000',
          },
        },
        heading3: {
          paragraph: {
            spacing: {
              before: 120,
            },
            numbering: { reference: 'headings', level: 2 },
          },
          run: {
            size: 22,
            bold: true,
            color: '000000',
          },
        },
        heading4: {
          paragraph: {
            spacing: {
              before: 80,
            },
          },
          run: {
            size: 22,
            bold: true,
            color: '000000',
          },
        },

        document: {
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: {
              before: 80,
            },
          },
          run: {
            size: 22,
            font: 'Calibri',
          },
        },
      },
    },
    sections: [
      getInitialSections({
        review,
        literatureSearchReportPlanText,
        attributesStructure,
        inclusionCriteria,
        citations,
        appraisalCriteria,
        isPlanIncluded,
        isReportIncluded,
      }),

      ...(isReportIncluded
        ? [
            buildPrismaSection(prismaDiagramImage),
            {
              properties: {
                type: SectionType.NEXT_PAGE,
                page: {
                  size: {
                    orientation: PageOrientation.LANDSCAPE,
                  },
                },
              },
              children: [
                ...buildSearchTermsAndLimitsSection(review, true, citations),
              ],
            },

            buildScreenigResultSection(review, studiesByType),
            ...(review.plan?.appraisalPlan.isImdrfMdce2019Applicable ||
            review.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable ||
            review.plan?.appraisalPlan.isPeerReviewStatusApplicable
              ? [_buildAppraisalResultSection(review, studiesByType)]
              : []),
            buildAttributeExtractionSection(
              review,
              attributesStructure,
              includedStudies,
              studiesByType,
              dataExtraction,
            ),
            referencesSection,
          ]
        : []),

      getFiguresAndTablesReferencesSections(),
    ],
  })

  return Packer.toBlob(doc)
}

function buildHeaderSection(
  review: Review,
  literatureSearchReportPlanText: string,
): Header {
  return new Header({
    children: [
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        borders: {
          insideVertical: { style: BorderStyle.NONE },
          top: { style: BorderStyle.NONE },
          bottom: { style: BorderStyle.NONE },
          right: { style: BorderStyle.NONE },
          left: { style: BorderStyle.NONE },
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                width: { size: 83, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text:
                          (review.project?.name ?? '-') +
                          ' - ' +
                          (review?.name ?? '-') +
                          ' - ' +
                          literatureSearchReportPlanText,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                width: { size: 17, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Page ',
                      }),
                      new TextRun({
                        children: [PageNumber.CURRENT],
                        bold: true,
                      }),
                      new TextRun({
                        text: ' Of ',
                      }),
                      new TextRun({
                        children: [PageNumber.TOTAL_PAGES],
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  })
}

function buildTitleSection(
  review: Review,
  literatureSearchReportPlanText: string,
): Paragraph {
  return new Paragraph({
    text:
      (review?.project?.name ?? '') +
      ' - ' +
      (review?.name ?? '') +
      ' - ' +
      literatureSearchReportPlanText,
    heading: HeadingLevel.TITLE,
  })
}

function buildApprovalSection(review: Review): (Paragraph | Table)[] {
  const evaluatorGroups = review.evaluators || []
  const result: (Paragraph | Table)[] = []

  result.push(
    new Paragraph({
      text: 'Approval',
      heading: HeadingLevel.HEADING_4,
    }),

    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        // Header row added here
        new TableRow({
          children: [
            buildTableHeaderCell({ text: 'Role' }),
            buildTableHeaderCell({ text: 'Name' }),
            buildTableHeaderCell({ text: 'Date' }),
            buildTableHeaderCell({ text: 'Signature' }),
          ],
        }),
        ...(evaluatorGroups.length > 0
          ? generateEvaluatorGroupsTableRows(review.evaluators).flat()
          : [
              new TableRow({
                children: [
                  buildTableCell({
                    children: [new TextRun('')],
                  }),
                  buildTableCell({
                    children: [new TextRun('')],
                  }),
                  buildTableCell({
                    children: [new TextRun('')],
                  }),
                  buildTableCell({
                    children: [new TextRun('')],
                  }),
                ],
              }),
            ]),
      ],
    }),
  )

  return result
}

function generateEvaluatorGroupsTableRows(evaluators: Evaluator[]): TableRow[] {
  const tableRows: TableRow[] = []
  const evaluatorGroups = evaluators.reduce(
    (groups, evaluator) => {
      const group = groups.find((g) => g.role === evaluator.role)
      if (group) {
        group.members.push(evaluator.name)
      } else {
        groups.push({ role: evaluator.role, members: [evaluator.name] })
      }
      return groups
    },
    [] as { role: string; members: string[] }[],
  )

  for (const group of evaluatorGroups) {
    const firstRow = new TableRow({
      children: [
        buildTableHeaderCell({
          text: group.role,
          rowSpan: group.members.length,
        }),
        buildTableCell({
          children: [new TextRun(group.members[0] ?? '')],
        }),
        buildTableCell({
          children: [new TextRun('')],
        }),
        buildTableCell({
          children: [new TextRun('')],
        }),
      ],
    })

    tableRows.push(firstRow)

    for (let i = 1; i < group.members.length; i++) {
      const additionalRow = new TableRow({
        children: [
          buildTableCell({
            children: [new TextRun(group.members[i])],
          }),
          buildTableCell({
            children: [new TextRun('')],
          }),
          buildTableCell({
            children: [new TextRun('')],
          }),
        ],
      })
      tableRows.push(additionalRow)
    }
  }

  return tableRows
}

function buildOxfordLevelOfEvidenceSection(
  isImdrfMdce2019Applicable: boolean,
): (Paragraph | Table)[] {
  const result: (Paragraph | Table)[] = []
  const OxfordLevelOfEvidenceTableIndex = tableIndex()
  if (!isImdrfMdce2019Applicable)
    result.push(
      new Paragraph({
        text: 'Appraisal strategy',
        heading: HeadingLevel.HEADING_2,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: 'Included articles for the SOTA medical field are guidelines and practice guidelines. The data appraisal will be performed according to the Oxford level of evidence',
          }),
          new FootnoteReferenceRun(3),
          new TextRun({
            text: `in table ${OxfordLevelOfEvidenceTableIndex}.`,
          }),
        ],
      }),
    )
  result.push(
    new Paragraph({
      text: 'Oxford level of evidence',
      heading: HeadingLevel.HEADING_4,
    }),
  )
  tables.push(
    `Table ${OxfordLevelOfEvidenceTableIndex}: Study levels of evidence criteria.`,
  )
  result.push(
    new Paragraph({
      style: 'Caption',
      children: [
        new Bookmark({
          id: `Table ${OxfordLevelOfEvidenceTableIndex}: Study levels of evidence criteria.`,
          children: [
            new TextRun(
              `Table ${OxfordLevelOfEvidenceTableIndex}: Study levels of evidence criteria.`,
            ),
          ],
        }),
      ],
    }),
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        new TableRow({
          children: [
            buildTableHeaderCell({
              text: 'Question',
              width: { size: 20, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 1 (Level 1*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 2 (Level 2*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 3 (Level 3*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 4 (Level 4*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Step 5 (Level 5*)',
              width: { size: 16, type: WidthType.PERCENTAGE },
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              text: 'How common is the problem?',
              fillColor: HeaderCellFillColor.QUESTION,
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Local and current random sample surveys (or censuses)',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of surveys that allow matching to local circumstances**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Local non-random sample**')],
            }),
            buildTableCell({
              children: [new TextRun('Case-series**')],
            }),
            buildTableCell({
              children: [new TextRun('n/a')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              fillColor: HeaderCellFillColor.QUESTION,
              text: 'Is this diagnostic or monitoring test accurate? (Diagnosis)',
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of cross sectional studies with consistently applied reference standard and blinding',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Individual cross sectional studies with consistently applied reference standard and blinding',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Non-consecutive studies, or studies without consistently applied reference standards**',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Case-control studies, or “poor or non-independent reference standard**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Mechanism-based reasoning')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              fillColor: HeaderCellFillColor.QUESTION,
              text: 'What will happen if we do not add a therapy? (Prognosis)',
            }),
            buildTableCell({
              children: [
                new TextRun('Systematic review of inception cohort studies'),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Inception cohort studies')],
            }),
            buildTableCell({
              children: [
                new TextRun('Cohort study or control arm of randomized trial*'),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Case-series or casecontrol studies, or poor quality prognostic cohort study**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('n/a')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              fillColor: HeaderCellFillColor.QUESTION,
              text: 'Does this intervention help? (Treatment Benefits)',
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of randomized trials or n-of-1 trials',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Randomized trial or observational study with dramatic effect',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Non-randomized controlled cohort/follow-up study**',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Case-series, case-control studies, or historically controlled studies**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Mechanism-based reasoning')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              fillColor: HeaderCellFillColor.QUESTION,
              text: 'What are the COMMON harms? (Treatment Harms)',
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of randomized trials, systematic review of nested case-control studies, nof-1 trial with the patient you are raising the question about, or observational study with dramatic effect',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Individual randomized trial or (exceptionally) observational study with dramatic effect',
                ),
              ],
            }),
            buildTableCell({
              rowSpan: 2,
              children: [
                new TextRun(
                  'Non-randomized controlled cohort/follow-up study (post-marketing surveillance) provided there are sufficient numbers to rule out a common harm. (For long-term harms the duration of follow-up must be sufficient.)**',
                ),
              ],
            }),
            buildTableCell({
              rowSpan: 2,
              children: [
                new TextRun('Case-series, case-control, or historically'),
              ],
            }),
            buildTableCell({
              rowSpan: 2,
              children: [new TextRun('Mechanism-based reasoning')],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              text: 'What are the RARE harms? (Treatment Harms',
              fillColor: HeaderCellFillColor.QUESTION,
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Systematic review of randomized trials or n-of-1 trial',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Randomized trial or (exceptionally) observational study with dramatic effect',
                ),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            buildTableHeaderCell({
              text: 'Is this (early detection) test worthwhile? (Screening)',
              fillColor: HeaderCellFillColor.QUESTION,
            }),
            buildTableCell({
              children: [new TextRun('Systematic review of randomized trials')],
            }),
            buildTableCell({
              children: [new TextRun('Randomized trial')],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Non -randomized controlled cohort/follow-up study**',
                ),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun(
                  'Case-series, case-control, or historically controlled studies**',
                ),
              ],
            }),
            buildTableCell({
              children: [new TextRun('Mechanism-based reasoning')],
            }),
          ],
        }),
      ],
    }),
    new Paragraph({
      text: '* Level may be graded down on the basis of study quality, imprecision, indirectness (study PICO does not match questions PICO), because of inconsistency between studies, or because the absolute effect size is very small; Level may be graded up if there is a large or very large effect size.',
      style: 'TableFooter',
    }),
    new Paragraph({
      text: '** As always, a systematic review is generally better than an individual study.',
      style: 'TableFooter',
    }),
  )
  return result
}

function buildMethodologySection(
  review: Review,
  inclusionCriteria: InclusionCriteria,
): (Paragraph | Table)[] {
  const result: (Paragraph | Table)[] = []

  result.push(
    new Paragraph({
      text: 'Search methodology',
      heading: HeadingLevel.HEADING_1,
    }),
  )

  if (review.plan?.searchStrategySummary) {
    result.push(
      new Paragraph({
        text: review.plan.searchStrategySummary,
      }),
    )
  }

  result.push(
    new Paragraph({
      text: `Inclusion criteria ${
        inclusionCriteria.isPicoInclusionCriteriaApplicable
          ? '- PICO(TS) strategy'
          : ''
      }`,
      heading: HeadingLevel.HEADING_2,
    }),
    ...__buildInclusionCriteriaSection(inclusionCriteria),
  )

  result.push(
    new Paragraph({
      text: 'Exclusion criteria',
      heading: HeadingLevel.HEADING_2,
    }),
    ..._buildExclusionCriteriaSection(review),
  )

  result.push(
    new Paragraph({
      text: 'Databases',
      heading: HeadingLevel.HEADING_2,
    }),
    ..._buildImportSourcesSection(review),
  )

  return result
}

function __buildInclusionCriteriaSection(inclusionCriteria: InclusionCriteria) {
  const inclusionCriteriaSection: (Paragraph | Table)[] = []

  const buildInclusionCriteriaRow = (headerText: string, data: string[]) =>
    new TableRow({
      children: [
        buildTableHeaderCell({
          text: headerText,
          columnSpan: 1,
          width: {
            size: inclusionCriteria.isPicoInclusionCriteriaApplicable
              ? 3500
              : 1500,
            type: WidthType.DXA,
          },
        }),
        buildTableCell({
          columnSpan: 2,
          children: buildTextRunArray(data),
        }),
      ],
    })

  const buildTextRunArray = (data: string[]) =>
    data.map(
      (c: string, i: number) =>
        new TextRun({
          text: c,
          break: i > 0 ? 1 : 0,
        }),
    )

  const picoInclusionCriteriaRows = [
    buildInclusionCriteriaRow(
      'Patient, problem or population',
      inclusionCriteria?.patient ?? [],
    ),
    buildInclusionCriteriaRow(
      'Intervention',
      inclusionCriteria?.intervention ?? [],
    ),
    buildInclusionCriteriaRow(
      'Comparison, control, or comparator',
      inclusionCriteria?.comparisonControlComparator ?? [],
    ),
    buildInclusionCriteriaRow('Outcomes', inclusionCriteria?.outcomes ?? []),
    buildInclusionCriteriaRow(
      'Study design',
      inclusionCriteria?.studyDesign ?? [],
    ),
    buildInclusionCriteriaRow('Timing', inclusionCriteria?.timing ?? []),
  ]

  const inclusionCriteriaRow = [
    buildInclusionCriteriaRow(
      'Inclusion criteria',
      inclusionCriteria?.criteria ?? [],
    ),
  ]

  const inclusionCriteriaTableIndex = tableIndex()
  tables.push(`Table ${inclusionCriteriaTableIndex}: Inclusion criteria`)

  inclusionCriteriaSection.push(
    new Paragraph(
      `Table ${inclusionCriteriaTableIndex} summarizes the inclusion criteria of the systematic literature search.`,
    ),
    new Paragraph({
      children: [
        new Bookmark({
          id: `Table ${inclusionCriteriaTableIndex}: Inclusion criteria`,
          children: [
            new TextRun(
              `Table ${inclusionCriteriaTableIndex}: Inclusion criteria`,
            ),
          ],
        }),
      ],
      style: 'Caption',
    }),
  )

  inclusionCriteriaSection.push(
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: inclusionCriteria.isPicoInclusionCriteriaApplicable
        ? picoInclusionCriteriaRows
        : inclusionCriteriaRow,
    }),
  )

  return inclusionCriteriaSection
}

function buildScopeOfTheSystematicReviewSection(
  summary: string,
  scopeOfSystematicReview: AttributeStructure[],
): (Paragraph | Table)[] {
  const result: (Paragraph | Table)[] = []

  result.push(
    new Paragraph({
      text: 'Scope',
      heading: HeadingLevel.HEADING_1,
    }),
  )

  if (summary) {
    result.push(
      new Paragraph({
        text: summary,
      }),
    )
  }

  if (scopeOfSystematicReview.length > 0) {
    const scopeTableIndex = tableIndex()
    tables.push(
      `Table ${scopeTableIndex}: Question that must be addressed by the literature search.`,
    )

    const tableContent: (Paragraph | Table)[] = [
      new Paragraph({
        children: [
          new TextRun({
            text: `Table ${scopeTableIndex}: summarizes questions that must be addressed by the.`,
          }),
        ],
      }),
      new Paragraph({
        style: 'Caption',
        children: [
          new Bookmark({
            id: `Table ${scopeTableIndex}: Question that must be addressed by the literature search.`,
            children: [
              new TextRun(
                `Table ${scopeTableIndex}: Question that must be addressed by the literature search.`,
              ),
            ],
          }),
        ],
      }),
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          // Header row
          new TableRow({
            children: [
              buildTableHeaderCell({
                text: 'Scope',
              }),
              buildTableHeaderCell({
                text: 'Question',
              }),
            ],
          }),
          ...scopeOfSystematicReview.map(
            (s) =>
              new TableRow({
                children: [
                  buildTableCell({
                    children: [
                      new TextRun({
                        bold: true,
                        text: s.label ?? '-',
                      }),
                    ],
                  }),
                  buildTableCell({
                    children: [
                      new TextRun({
                        text: s.question ?? '-',
                      }),
                    ],
                  }),
                ],
              }),
          ),
        ],
      }),
    ]

    result.push(...tableContent)
  } else {
    result.push(
      new Paragraph({
        children: [
          new TextRun({
            text: 'Enter scope',
            highlight: 'yellow',
          }),
        ],
      }),
    )
  }

  return result
}

function buildPrismaSection(image: string): ISectionOptions {
  figures.push('Prisma diagram')
  return {
    properties: {
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
    children: [
      new Paragraph({
        alignment: AlignmentType.CENTER,

        children: [
          new Bookmark({
            id: 'Prisma diagram',
            children: [],
          }),
          new ImageRun({
            data: image,
            type: 'jpg',
            altText: {
              description: 'Prisma diagram',
              name: 'Prisma diagram',
              title: 'Prisma diagram',
            },
            transformation: {
              width: 930,
              height: 600,
            },
          }),
        ],
      }),
    ],
  }
}

function _buildAppraisalSection(appraisalCriteria: AppraisalCriterion[]) {
  const rows: TableRow[] = appraisalCriteria
    .map((criterion) => {
      return (
        criterion.answers?.map((answer, index) => {
          return new TableRow({
            children: [
              ...(index === 0
                ? [
                    buildTableCell({
                      children: [new TextRun(criterion.title)],
                      rowSpan: criterion.answers?.length,
                    }),
                  ]
                : []),
              ...(index === 0
                ? [
                    buildTableCell({
                      children: [new TextRun(criterion.question)],
                      rowSpan: criterion.answers?.length,
                    }),
                  ]
                : []),
              buildTableCell({
                children: [new TextRun(criterion.id + (index + 1))],
                width: {
                  size: 400,
                  type: WidthType.DXA,
                },
              }),
              buildTableCell({
                children: [new TextRun(answer.title)],
              }),
            ],
          })
        }) ?? []
      )
    })
    .flat()
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          buildTableHeaderCell({
            text: 'Criterion',
            width: {
              size: 2500,
              type: WidthType.DXA,
            },
          }),
          buildTableHeaderCell({
            text: 'Description',
          }),
          buildTableHeaderCell({
            text: 'Grading system',
            columnSpan: 2,

            width: {
              size: 2700,
              type: WidthType.DXA,
            },
          }),
        ],
      }),
      ...rows,
    ],
  })
}

function _buildAppraisalResultSection(
  review: Review,
  studiesByType: Record<ReviewItemType, StudyEntry[]>,
): ISectionOptions {
  const children: (Paragraph | Table)[] = [
    new Paragraph({
      text: 'Appraisal results',
      heading: HeadingLevel.HEADING_1,
    }),
  ]

  if (review.plan?.appraisalPlan.isImdrfMdce2019Applicable) {
    children.push(
      ..._buildIMDFAppraisalResultSection(review, studiesByType).children,
    )
  }

  if (review.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable) {
    children.push(
      ..._buildOxfordLevelOfEvidenceAppraisalResultSection(
        review,
        studiesByType,
      ).children,
    )
  }

  if (review.plan?.appraisalPlan.isPeerReviewStatusApplicable) {
    children.push(
      ..._buildPeerReviewAppraisalResultSection(review, studiesByType).children,
    )
  }

  return { children }
}

function _buildIMDFAppraisalResultSection(
  review: Review,
  studiesByType: Record<ReviewItemType, StudyEntry[]>,
) {
  const appraisalResultTableIndex = tableIndex()
  tables.push(
    `Table ${appraisalResultTableIndex}: the IMDRF MDCE 2019 appraisal of the included articles`,
  )
  const findAppraisalAnswerIndex = (
    appraisalCriterionId: string,
    studyAppraisalAnswerId: string,
  ) => {
    let answerIndexText = '-'
    const criterion = review?.plan?.appraisalCriteria?.find(
      (s) => s.id === appraisalCriterionId,
    )
    if (criterion) {
      const answerIndex = criterion.answers?.findIndex(
        (a) => a.id === studyAppraisalAnswerId,
      )
      if (answerIndex !== -1) {
        answerIndexText = appraisalCriterionId + (answerIndex + 1).toString()
      }
    }

    return answerIndexText
  }

  const buildAppraisalResultRow = (
    study: ReviewItem,
    studiesByType: Record<ReviewItemType, StudyEntry[]>,
  ) => {
    return new TableRow({
      children: [
        buildTableCell({
          children: [
            new TextRun({
              text:
                studiesByType[study.type].find((a) => a.studyId === study.id)
                  ?.inText ?? '-',
            }),
          ],
        }),
        ...['D', 'A', 'P', 'R', 'T'].map((t) => {
          return buildTableCell({
            children: [
              new TextRun({
                text: study.appraisal?.[t]
                  ? findAppraisalAnswerIndex(t, study.appraisal?.[t])
                  : '-',
              }),
            ],
          })
        }),
        ...['O', 'F', 'S', 'C'].map((t) => {
          return buildTableCell({
            children: [
              new TextRun({
                text: study.appraisal?.[t]
                  ? findAppraisalAnswerIndex(t, study.appraisal?.[t])
                  : '-',
              }),
            ],
          })
        }),
      ],
    })
  }

  const buildAppraisalTableHeaderRow = () => {
    return new TableRow({
      children: [
        buildTableHeaderCell({
          text: 'Study ID',
          width: { size: 2500, type: WidthType.DXA },
        }),
        buildTableHeaderCell({
          text: 'Data Suitability',
          columnSpan: 5,
          width: { size: 1800, type: WidthType.DXA },
        }),
        buildTableHeaderCell({
          text: 'Data Contribution',
          columnSpan: 4,
          width: { size: 1800, type: WidthType.DXA },
        }),
      ],
    })
  }

  const appraisalResultRows = review.studies
    .map((s) => {
      return s.state === StudyState.INCLUDED
        ? buildAppraisalResultRow(s, studiesByType)
        : []
    })
    .flat()
  return {
    children: [
      ...[
        new Paragraph({
          text: 'IMDRF',
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Table ${appraisalResultTableIndex} summarizes the IMDRF appraisal of the included articles.`,
            }),
          ],
        }),
        new Paragraph({
          style: 'Caption',
          children: [
            new Bookmark({
              id: `Table ${appraisalResultTableIndex}: the IMDRF appraisal of the included articles`,
              children: [
                new TextRun(
                  `Table ${appraisalResultTableIndex}: the IMDRF appraisal of the included articles`,
                ),
              ],
            }),
          ],
        }),
      ],

      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [buildAppraisalTableHeaderRow(), ...appraisalResultRows],
      }),
    ],
  }
}

function _buildOxfordLevelOfEvidenceAppraisalResultSection(
  review: Review,
  studiesByType: Record<ReviewItemType, StudyEntry[]>,
) {
  const appraisalResultTableIndex = tableIndex()
  tables.push(
    `Table ${appraisalResultTableIndex}: the oxford level of evidence appraisal of the included articles`,
  )

  const buildAppraisalResultRow = (
    study: ReviewItem,
    studiesByType: Record<ReviewItemType, StudyEntry[]>,
  ) => {
    return new TableRow({
      children: [
        buildTableCell({
          children: [
            new TextRun({
              text:
                studiesByType[study.type].find((a) => a.studyId === study.id)
                  ?.inText ?? '-',
            }),
          ],
        }),
        buildTableCell({
          children: [
            new TextRun({
              text: study.oxfordLevelOfEvidence
                ? study.oxfordLevelOfEvidence
                : '-',
            }),
          ],
        }),
      ],
    })
  }

  const buildAppraisalTableHeaderRow = () => {
    return new TableRow({
      children: [
        buildTableHeaderCell({
          text: 'Study ID',
          width: { size: 2500, type: WidthType.DXA },
        }),
        buildTableHeaderCell({
          text: 'Oxford level of evidence',
          width: { size: 1300, type: WidthType.DXA },
        }),
      ],
    })
  }

  const appraisalResultRows = review.studies
    .map((s) => {
      return s.state === StudyState.INCLUDED
        ? buildAppraisalResultRow(s, studiesByType)
        : []
    })
    .flat()
  return {
    children: [
      ...[
        new Paragraph({
          text: 'Oxford level of evidence appraisal results',
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Table ${appraisalResultTableIndex} summarizes the oxford level of evidence appraisal of the included articles.`,
            }),
          ],
        }),
        new Paragraph({
          style: 'Caption',
          children: [
            new Bookmark({
              id: `Table ${appraisalResultTableIndex} summarizes the oxford level of evidence appraisal of the included articles`,
              children: [
                new TextRun(
                  `Table ${appraisalResultTableIndex} summarizes the oxford level of evidence appraisal of the included articles`,
                ),
              ],
            }),
          ],
        }),
      ],

      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [buildAppraisalTableHeaderRow(), ...appraisalResultRows],
      }),
    ],
  }
}

function _buildPeerReviewAppraisalResultSection(
  review: Review,
  studiesByType: Record<ReviewItemType, StudyEntry[]>,
) {
  const peerReviewStatusMap = {
    [PeerReviewStatus.yes]: {
      text: 'Yes',
    },
    [PeerReviewStatus.no]: {
      text: 'No',
    },
    [PeerReviewStatus.notApplicable]: {
      text: 'Not applicable',
    },
  }
  const appraisalResultTableIndex = tableIndex()
  tables.push(
    `Table ${appraisalResultTableIndex}: the peer review status appraisal of the included articles`,
  )

  const buildAppraisalResultRow = (
    study: ReviewItem,
    studiesByType: Record<ReviewItemType, StudyEntry[]>,
  ) => {
    return new TableRow({
      children: [
        buildTableCell({
          children: [
            new TextRun({
              text:
                studiesByType[study.type].find((a) => a.studyId === study.id)
                  ?.inText ?? '-',
            }),
          ],
        }),
        buildTableCell({
          children: [
            new TextRun({
              text: study.peerReviewStatus
                ? peerReviewStatusMap[study.peerReviewStatus].text
                : '-',
            }),
          ],
        }),
      ],
    })
  }

  const buildAppraisalTableHeaderRow = () => {
    return new TableRow({
      children: [
        buildTableHeaderCell({
          text: 'Study ID',
          width: { size: 2500, type: WidthType.DXA },
        }),
        buildTableHeaderCell({
          text: 'peer review status',
          width: { size: 1300, type: WidthType.DXA },
        }),
      ],
    })
  }

  const appraisalResultRows = review.studies
    .map((s) => {
      return s.state === StudyState.INCLUDED
        ? buildAppraisalResultRow(s, studiesByType)
        : []
    })
    .flat()
  return {
    children: [
      ...[
        new Paragraph({
          text: 'Peer review status',
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Table ${appraisalResultTableIndex} summarizes the peer review status appraisal of the included articles.`,
            }),
          ],
        }),
        new Paragraph({
          style: 'Caption',
          children: [
            new Bookmark({
              id: `Table ${appraisalResultTableIndex} summarizes the peer review status appraisal of the included articles`,
              children: [
                new TextRun(
                  `Table ${appraisalResultTableIndex} summarizes the peer review  status appraisal of the included articles`,
                ),
              ],
            }),
          ],
        }),
      ],

      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [buildAppraisalTableHeaderRow(), ...appraisalResultRows],
      }),
    ],
  }
}

function buildAppraisalStrategySection(
  review: Review,
  appraisalCriteria: AppraisalCriterion[],
): (Paragraph | Table)[] {
  const result: (Paragraph | Table)[] = []
  if (review.plan?.appraisalPlan.isImdrfMdce2019Applicable) {
    const suitabilityCriteriaTableIndex = tableIndex()
    const dataContributionCriteriaTableIndex = tableIndex()
    tables.push(
      `Table ${suitabilityCriteriaTableIndex}: Criteria for the appraisal of suitability`,
      `Table ${dataContributionCriteriaTableIndex}: Criteria for the appraisal of the data contribution`,
    )
    result.push(
      new Paragraph({
        text: 'Appraisal strategy',
        heading: HeadingLevel.HEADING_2,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: `The included records will be appraised according to IMDRF MDCE 2019`,
          }),
          new FootnoteReferenceRun(1),

          new TextRun({
            text: `and MEDDEV 2.7.1 Rev. 4.`,
          }),
          new FootnoteReferenceRun(2),
          new TextRun({
            text: ` Table ${suitabilityCriteriaTableIndex} and Table ${dataContributionCriteriaTableIndex} detail the suitability and data contribution criteria.`,
          }),

          ...(review.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable
            ? [
                new TextRun({
                  text: ` In addition, the data will be appraised according to the Oxford level of evidence,`,
                }),
                new FootnoteReferenceRun(3),
                new TextRun({
                  text: ` Table ${dataContributionCriteriaTableIndex + 1}.`,
                }),
              ]
            : []),
        ],
      }),
      new Paragraph({
        text: 'Appraisal of suitability',
        heading: HeadingLevel.HEADING_4,
      }),

      new Paragraph({
        style: 'Caption',
        children: [
          new Bookmark({
            id: `Table ${suitabilityCriteriaTableIndex}: Criteria for the appraisal of suitability`,
            children: [
              new TextRun(
                `Table ${suitabilityCriteriaTableIndex}: Criteria for the appraisal of suitability`,
              ),
            ],
          }),
        ],
      }),
      _buildAppraisalSection(
        appraisalCriteria.filter((c) =>
          ['D', 'A', 'P', 'R', 'T'].includes(c.id),
        ),
      ),
      new Paragraph({
        text: 'Appraisal of the data contribution',
        heading: HeadingLevel.HEADING_4,
      }),
      new Paragraph({
        style: 'Caption',
        children: [
          new Bookmark({
            id: `Table ${dataContributionCriteriaTableIndex}: Criteria for the appraisal of the data contribution`,
            children: [
              new TextRun(
                `Table ${dataContributionCriteriaTableIndex}: Criteria for the appraisal of the data contribution`,
              ),
            ],
          }),
        ],
      }),
      _buildAppraisalSection(
        appraisalCriteria.filter((c) => ['O', 'F', 'S', 'C'].includes(c.id)),
      ),
    )
  }
  return result
}

function buildPeerAppraisalSection(review: Review): (Paragraph | Table)[] {
  const result: (Paragraph | Table)[] = []
  if (review.plan?.appraisalPlan.isPeerReviewStatusApplicable) {
    const peerAppraisalTableIndex = tableIndex()
    tables.push(
      `Table ${peerAppraisalTableIndex}: Criteria for the peer review status appraisal`,
    )
    result.push(
      new Paragraph({
        text: 'Appraisal of the peer review status',
        heading: HeadingLevel.HEADING_4,
      }),
      new Paragraph({
        style: 'Caption',
        children: [
          new Bookmark({
            id: `Table ${peerAppraisalTableIndex}: Criteria for the peer review status appraisal`,
            children: [
              new TextRun(
                `Table ${peerAppraisalTableIndex}: Criteria for the peer review status appraisal`,
              ),
            ],
          }),
        ],
      }),
    )
    result.push(
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              buildTableHeaderCell({
                text: 'Criterion',
              }),
            ],
          }),
          new TableRow({
            children: [
              buildTableCell({
                children: [new TextRun('Yes')],
              }),
            ],
          }),
          new TableRow({
            children: [
              buildTableCell({
                children: [new TextRun('No')],
              }),
            ],
          }),
          new TableRow({
            children: [
              buildTableCell({
                children: [new TextRun('Not applicable')],
              }),
            ],
          }),
        ],
      }),
    )
  }
  return result
}

function _buildTitleAndAbstractCriteriaTable(criteria: string[]) {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      ...criteria.map(
        (c, i: number) =>
          new TableRow({
            children: [
              buildTableHeaderCell({
                text: `Criterion ${i + 1}a`,
                width: { size: 1500, type: WidthType.DXA },
              }),
              buildTableCell({
                children: [
                  new TextRun({
                    text: c,
                  }),
                ],
              }),
            ],
          }),
      ),
    ],
  })
}
function _buildFullTextCriteriaTable(
  criteria: string[],
  isTitleAndAbstractCriteriaIncluded: boolean,
) {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      ...(criteria.length > 0
        ? criteria.map(
            (c, i: number) =>
              new TableRow({
                children: [
                  buildTableHeaderCell({
                    text: `Criterion ${i + 1}${isTitleAndAbstractCriteriaIncluded ? 'b' : ''}`,
                    width: { size: 1500, type: WidthType.DXA },
                  }),
                  buildTableCell({
                    children: [
                      new TextRun({
                        text: c,
                      }),
                    ],
                  }),
                ],
              }),
          )
        : [
            new TableRow({
              children: [
                buildTableHeaderCell({
                  text: isTitleAndAbstractCriteriaIncluded
                    ? 'Full text exclusion criteria'
                    : 'Exclusion criteria',
                  width: { size: 1500, type: WidthType.DXA },
                }),
                buildTableCell({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
              ],
            }),
          ]),
    ],
  })
}

function _buildExclusionCriteriaSection(review: Review) {
  const exclusionCriteriaSection: (Paragraph | Table)[] = []

  const exclusionText =
    (review.plan?.screeningPlan.titleAndAbstractCriteria?.length ?? 0) > 0
      ? 'Screening will be conducted in a two-phase process: an initial title and abstract screening, followed by a full-text screening.'
      : 'Screening will be conducted in a single phase, reviewing both title/abstract and full text simultaneously.'

  exclusionCriteriaSection.push(
    new Paragraph({
      children: [
        new TextRun({
          text: exclusionText,
        }),
      ],
    }),
  )

  if ((review.plan?.screeningPlan.titleAndAbstractCriteria?.length ?? 0) > 0) {
    const titleAndAbstractCriteriaExclusionCriteriaTable = tableIndex()
    const titleAndAbstractCriteriaExclusionCriteriaText = `Table ${titleAndAbstractCriteriaExclusionCriteriaTable}: summarizes the exclusion criteria for title-and-abstract screening.`

    tables.push(titleAndAbstractCriteriaExclusionCriteriaText)
    exclusionCriteriaSection.push(
      new Paragraph({
        children: [
          new Bookmark({
            id: titleAndAbstractCriteriaExclusionCriteriaText,
            children: [
              new TextRun(titleAndAbstractCriteriaExclusionCriteriaText),
            ],
          }),
        ],
        style: 'Caption',
      }),

      _buildTitleAndAbstractCriteriaTable(
        review.plan?.screeningPlan.titleAndAbstractCriteria ?? [],
      ),
    )
  }

  const fullTextExclusionCriteriaTable = tableIndex()
  const fullTextExclusionCriteriatext =
    (review.plan?.screeningPlan.titleAndAbstractCriteria?.length ?? 0) > 0
      ? `Table ${fullTextExclusionCriteriaTable}: summarizes the exclusion criteria for full-text screening.`
      : `Table ${fullTextExclusionCriteriaTable}: summarizes the exclusion criteria screening.`
  tables.push(fullTextExclusionCriteriatext)

  exclusionCriteriaSection.push(
    new Paragraph({
      style: 'Caption',
      children: [
        new Bookmark({
          id: fullTextExclusionCriteriatext,
          children: [new TextRun(fullTextExclusionCriteriatext)],
        }),
      ],
    }),
    _buildFullTextCriteriaTable(
      review.plan?.screeningPlan.fullTextCriteria ?? [],
      (review.plan?.screeningPlan.titleAndAbstractCriteria.length ?? 0) > 0,
    ),
  )

  return exclusionCriteriaSection
}

function _buildImportSourcesSection(review: Review) {
  const importSourcesSection: Paragraph[] = []

  importSourcesSection.push(
    new Paragraph({
      children: [
        new TextRun({
          text: 'The search will be conducted in the following sources:',
        }),
      ],
    }),
  )

  const fsnSources = [
    {
      id: 'bfarm',
      name: 'BfArM',
    },
    {
      id: 'ansm',
      name: 'ANSM',
    },
    {
      id: 'sara',
      name: 'SARA',
    },
    {
      id: 'mhra',
      name: 'MHRA',
    },
    {
      id: 'fda',
      name: 'FDA',
    },
    {
      id: 'moh',
      name: 'ΙΥ&ΥΔΥ',
    },
  ]
  const fsnSourcesids = ['bfarm', 'ansm', 'sara', 'mhra', 'fda']

  const fsnDatabases = (searches: Search[]) => {
    const allDatabases = new Set<string>()

    searches.forEach((search) => {
      const databases = Array.from(
        new Set(search.studies.map((s) => s.metadata.source).flat()),
      )

      databases.forEach((source) => {
        if (fsnSourcesids.includes(source)) {
          allDatabases.add(source)
        }
      })
    })

    return Array.from(allDatabases).map((source) => {
      return {
        name: fsnSources.find((s) => s.id === source)?.name,
      }
    })
  }

  const sourceSections = [
    {
      ids: [
        BuiltInImportSourceId.PUBMED,
        BuiltInImportSourceId.OPEN_ALEX,
        BuiltInImportSourceId.DIMENSIONS_AI,
        BuiltInImportSourceId.PMC,
        BuiltInImportSourceId.EUROPE_PMC,
        BuiltInImportSourceId.LIVIVO,
        BuiltInImportSourceId.BASE,
        BuiltInImportSourceId.EMBASE,
      ],
      title: 'Scholarly article aggregated databases',
    },

    {
      ids: [BuiltInImportSourceId.GOOGLE_SCHOLAR],
      title: 'Scholarly articles search engines',
    },

    {
      ids: [
        BuiltInImportSourceId.WILEY_ONLINE_LIBRARY,
        BuiltInImportSourceId.MAG_ONLINE,
        BuiltInImportSourceId.SCIENCE_DIRECT,
      ],
      title: 'Scholarly article publisher specific sources',
    },
    {
      ids: [BuiltInImportSourceId.COCHRANE, BuiltInImportSourceId.PROSPERO],
      title: 'Systematic review sources',
    },
    {
      ids: [BuiltInImportSourceId.ICTRP],
      title: 'Clinical trials sources',
    },
    {
      ids: [BuiltInImportSourceId.MAUDE],
      title: 'Incident sources',
    },
    {
      ids: [BuiltInImportSourceId.CENELEC],
      title: 'Standards sources',
    },
    {
      ids: [BuiltInImportSourceId.MDCG],
      title: 'Guidance sources',
    },
    {
      ids: [BuiltInImportSourceId.FIELD_SAFETY_NOTICES],
      title: 'Safety information',
      customData: fsnDatabases(review.searches ?? []),
    },
  ]

  sourceSections.forEach((section) => {
    const importSources = review.plan?.importPlan?.importSources
      ?.filter((source) =>
        section.ids.includes(source.id as BuiltInImportSourceId),
      )
      .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))

    if (importSources && importSources.length > 0) {
      importSourcesSection.push(
        new Paragraph({
          text: section.title,
          heading: HeadingLevel.HEADING_3,
        }),
      )

      importSources.forEach((source) => {
        if (source.id !== BuiltInImportSourceId.FIELD_SAFETY_NOTICES)
          importSourcesSection.push(
            new Paragraph({
              children: [
                new ExternalHyperlink({
                  children: [
                    new TextRun({
                      style: 'Hyperlink',
                      text: `${source.name}`,
                    }),
                  ],
                  link: source.url ? source.url : '',
                }),
              ],
              bullet: { level: 0 },
            }),
          )
      })
    }

    if (section.customData && section.title === 'Safety information') {
      section.customData.forEach((entry) => {
        importSourcesSection.push(
          new Paragraph({
            text: `${entry.name}`,
            bullet: { level: 0 },
          }),
        )
      })
    }
  })
  return importSourcesSection
}

function buildSearchTermsAndLimitsSection(
  review: Review,
  includeStats: boolean,
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  },
): (Paragraph | Table)[] {
  const _buildSearchTermsAndLimitsRows = (
    review: Review,
    includeStats: boolean,
  ) => {
    const searchTermsAndLimitsRows: TableRow[] = []
    const searchesResults = generateReviewSearchsAndLimtsResults(
      review,
      includeStats,
    )
    searchesResults.forEach((s) => {
      let sourceText = ''
      if (s.parentStudyId) {
        const parentReference = citations.findByStudyId(s.parentStudyId).inText
        const parentReferenceText = ` parent reference: ${parentReference}`
        sourceText = `${s.source}${parentReferenceText}`
      } else {
        sourceText = s.source
      }

      searchTermsAndLimitsRows.push(
        new TableRow({
          children: [
            buildTableCell({
              children: [
                new TextRun({
                  text: s.searchNumber.toString(),
                }),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun({
                  text: sourceText,
                }),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun({
                  text: s.searchDate,
                }),
              ],
            }),
            buildTableCell({
              children: [
                new TextRun({
                  text: 'Search: ',
                  bold: true,
                }),
                new TextRun({
                  text: s.query,
                }),
                new TextRun({
                  text: 'Filters: ',
                  bold: true,
                  break: 2,
                }),
                new TextRun({
                  text: s.filters,
                }),
                new TextRun({
                  text: 'Search details: ',
                  bold: true,
                  break: 2,
                }),
                new TextRun({
                  text: s.searchDetails,
                }),
              ],
            }),

            ...(includeStats
              ? [
                  s.empty
                    ? buildTableCell({
                        children: [
                          new TextRun({
                            text: 'The search returned no records',
                          }),
                        ],
                      })
                    : buildTableCell({
                        children: [
                          new TextRun({
                            text: `Hits: ${s.statSummary.hits}`,
                            bold: true,
                          }),

                          new TextRun({
                            text: `Duplicates: ${s.statSummary.duplicated}`,
                            bold: true,
                            break: 1,
                          }),
                          new TextRun({
                            text: `Excluded: ${s.statSummary.excluded}`,
                            bold: true,
                            break: 1,
                          }),
                          ...s.statSummary.exclusionSummarytext
                            .split('\n')
                            .map((l) => {
                              return new TextRun({
                                text: l,
                                break: 1,
                              })
                            }),

                          new TextRun({
                            text: `Included: ${s.statSummary.included}`,
                            bold: true,
                          }),
                        ],
                      }),
                ]
              : []),
          ],
        }),
      )
    })
    return searchTermsAndLimitsRows
  }
  const searchTermsTableIndex = tableIndex()
  const tableTitle = includeStats
    ? 'The screening by search'
    : 'The search terms and limits'
  tables.push(`Table ${searchTermsTableIndex}: ${tableTitle}`)
  return [
    new Paragraph({
      text: includeStats
        ? 'Screening results by search'
        : 'Search terms and limits',
      heading: HeadingLevel.HEADING_2,
    }),

    new Paragraph({
      children: [
        new Bookmark({
          id: `Table ${searchTermsTableIndex}: ${tableTitle}`,
          children: [
            new TextRun(`Table ${searchTermsTableIndex}: ${tableTitle}`),
          ],
        }),
      ],
      style: 'Caption',
    }),

    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            buildTableHeaderCell({
              text: '#',
              width: { size: 2, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Source',
              width: { size: 4, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Search Date',
              width: { size: 4, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: 'Query',
              width: { size: 20, type: WidthType.PERCENTAGE },
            }),
            ...(includeStats
              ? [
                  buildTableHeaderCell({
                    text: 'Screening summary',
                    width: { size: 28, type: WidthType.PERCENTAGE },
                  }),
                ]
              : []),
          ],
        }),
        ..._buildSearchTermsAndLimitsRows(review, includeStats),
      ],
    }),
  ]
}

function buildScreenigResultSection(
  review: Review,
  studiesByType: Record<ReviewItemType, StudyEntry[]>,
): ISectionOptions {
  function buildSearchScreeningResultBreakdownSection(
    search: SearchScreeningResult,
  ): (Paragraph | Table)[] {
    const getShading = (studyState: string, fillColor: string) =>
      studyState === StudyState.INCLUDED ? { shading: { fill: fillColor } } : {}

    const cslStyle = review.project?.cslStyle
      ? review.project.cslStyle.charAt(0).toUpperCase() +
        review.project.cslStyle.replaceAll('-', ' ').slice(1)
      : '-'
    return [
      new Paragraph({
        text: `Search ${search.searchNumber}`,
        heading: HeadingLevel.HEADING_3,
      }),
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            tableHeader: true,
            children: [
              buildTableHeaderCell({
                text: `Reference of ${cslStyle ?? '-'}`,
                width: { size: 70, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Source',
                width: { size: 15, type: WidthType.PERCENTAGE },
              }),
              buildTableHeaderCell({
                text: 'Screening',
                width: { size: 15, type: WidthType.PERCENTAGE },
              }),
            ],
          }),
          ...(search.empty === false
            ? search.items.map((i) => {
                const shading = getShading(i.stateText, i.fillColor)
                const studyReference =
                  studiesByType[i.type].find((a) => a.studyId === i.id)
                    ?.inText ?? '-'
                const baseReviewItemTableCell = [
                  new TextRun({
                    text: studyReference,
                    bold: true,
                  }),
                  new TextRun({
                    text: 'Title: ',
                    break: studyReference ? 1 : 0,
                    bold: true,
                  }),
                  new TextRun({
                    text: i.title,
                  }),
                ]

                if (i.type === ReviewItemType.Article) {
                  baseReviewItemTableCell.push(
                    new TextRun({
                      text: 'Abstract: ',
                      break: 1,
                      bold: true,
                    }),
                  )
                  baseReviewItemTableCell.push(
                    new TextRun({
                      text: i.abstract,
                    }),
                  )
                } else if (i.type === ReviewItemType.Incident) {
                  baseReviewItemTableCell.push(
                    new TextRun({
                      text: 'Summary: ',
                      break: 1,
                      bold: true,
                    }),
                  )
                  baseReviewItemTableCell.push(
                    new TextRun({
                      text: i.summary,
                    }),
                  )
                }

                return new TableRow({
                  children: [
                    buildTableCell({
                      children: [...baseReviewItemTableCell],
                      ...shading,
                    }),
                    buildTableCell({
                      children: [new TextRun(search.source)],
                      ...shading,
                    }),

                    buildTableCell({
                      children: [
                        new TextRun({
                          ...(i.stateText === StudyState.INCLUDED && {
                            bold: true,
                          }),
                          text: i.stateText,
                        }),
                      ],
                      ...shading,
                    }),
                  ],
                })
              })
            : [
                new TableRow({
                  children: [
                    buildTableCell({
                      children: [
                        new TextRun({
                          text: 'The search returned no records',
                          bold: true,
                        }),
                      ],
                    }),
                    buildTableCell({
                      children: [new TextRun(search.source)],
                    }),

                    buildTableCell({
                      children: [new TextRun('-')],
                    }),
                  ],
                }),
              ]),
        ],
      }),
    ]
  }
  const screeningResults = generateReviewScreeningResults(review)
  return {
    properties: {
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
    children: [
      new Paragraph({
        text: 'Screening details',
        heading: HeadingLevel.HEADING_2,
      }),

      ...(screeningResults
        .map((search) => {
          return buildSearchScreeningResultBreakdownSection(search)
        })
        .flat() ?? []),
    ],
  }
}

function buildReferencesSection(
  studiesByType: Record<ReviewItemType, StudyEntry[]>,
): ISectionOptions {
  const children = [
    new Paragraph({
      text: 'References',
      heading: HeadingLevel.HEADING_1,
    }),
  ]
  if (studiesByType[ReviewItemType.FieldSafetyNotice].length > 0) {
    const fieldSafetyNoticeSection = _buildFieldSafetyReferencesSection(
      studiesByType[ReviewItemType.FieldSafetyNotice],
    )
    children.push(...fieldSafetyNoticeSection)
  }

  if (studiesByType[ReviewItemType.Article].length > 0) {
    const articleSection = _buildArticleReferencesSection(
      studiesByType[ReviewItemType.Article],
    )
    children.push(...articleSection)
  }

  if (studiesByType[ReviewItemType.Incident].length > 0) {
    const incidentSection = _buildIncidentsReferencesSection(
      studiesByType[ReviewItemType.Incident],
    )
    children.push(...incidentSection)
  }

  if (studiesByType[ReviewItemType.Guidance].length > 0) {
    const guidanceSection = _buildGuidancesReferencesSection(
      studiesByType[ReviewItemType.Guidance],
    )
    children.push(...guidanceSection)
  }
  if (studiesByType[ReviewItemType.Registration].length > 0) {
    const registrationSection = _buildRegistrationsReferencesSection(
      studiesByType[ReviewItemType.Registration],
    )
    children.push(...registrationSection)
  }

  return {
    children,
  }
}

function _buildArticleReferencesSection(
  articles: { studyId: Id; inText: string; fullText: string }[],
): Paragraph[] {
  articles.sort((a, b) => (a.inText || '').localeCompare(b.inText || ''))

  const children = [
    new Paragraph({
      text: 'Article references',
      heading: HeadingLevel.HEADING_2,
    }),
  ]

  articles.forEach((s) => {
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.inText ?? '',
            bold: true,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.fullText ?? '',
          }),
        ],
      }),
    )
  })

  return children
}

function _buildIncidentsReferencesSection(
  incident: { studyId: Id; inText: string; fullText: string }[],
): Paragraph[] {
  incident.sort((a, b) => (a.inText || '').localeCompare(b.inText || ''))

  const children = [
    new Paragraph({
      text: 'Incidents references',
      heading: HeadingLevel.HEADING_2,
    }),
  ]

  incident.forEach((s) => {
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.inText ?? '',
            bold: true,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.fullText ?? '',
          }),
        ],
      }),
    )
  })

  return children
}

function _buildGuidancesReferencesSection(
  guidance: { studyId: Id; inText: string; fullText: string }[],
): Paragraph[] {
  guidance.sort((a, b) => (b.inText || '').localeCompare(a.inText || ''))

  const children = [
    new Paragraph({
      text: 'Guidances references',
      heading: HeadingLevel.HEADING_2,
    }),
  ]

  guidance.forEach((s) => {
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.inText ?? '',
            bold: true,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.fullText ?? '',
          }),
        ],
      }),
    )
  })

  return children
}

function _buildFieldSafetyReferencesSection(
  fieldSafetyNotice: { studyId: Id; inText: string; fullText: string }[],
): Paragraph[] {
  fieldSafetyNotice.sort((a, b) =>
    (b.inText || '').localeCompare(a.inText || ''),
  )

  const children = [
    new Paragraph({
      text: 'Field safety notice references',
      heading: HeadingLevel.HEADING_2,
    }),
  ]

  fieldSafetyNotice.forEach((s) => {
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.inText ?? '-',
            bold: true,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.fullText ?? '',
          }),
        ],
      }),
    )
  })

  return children
}

function _buildRegistrationsReferencesSection(
  registrations: { studyId: Id; inText: string; fullText: string }[],
): Paragraph[] {
  registrations.sort((a, b) => (a.inText || '').localeCompare(b.inText || ''))

  const children = [
    new Paragraph({
      text: 'Registrations references',
      heading: HeadingLevel.HEADING_2,
    }),
  ]

  registrations.forEach((s) => {
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.inText ?? '',
            bold: true,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
    children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: s.fullText ?? '',
          }),
        ],
      }),
    )
  })

  return children
}

function buildTablesReferencesSection(): (Paragraph | Table)[] {
  const result = [
    new Paragraph({
      text: 'Tables references',
      heading: HeadingLevel.HEADING_1,
    }),
  ]

  tables.forEach((a) => {
    result.push(
      new Paragraph({
        children: [
          new InternalHyperlink({
            children: [
              new TextRun({
                text: a,
              }),
            ],
            anchor: a,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
  })

  return result
}

function buildFiguresReferencesSection(): (Paragraph | Table)[] {
  const result = [
    new Paragraph({
      text: 'Figures references',
      heading: HeadingLevel.HEADING_1,
    }),
  ]

  figures.forEach((a) => {
    result.push(
      new Paragraph({
        children: [
          new InternalHyperlink({
            children: [
              new TextRun({
                text: a,
              }),
            ],
            anchor: a,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    )
  })

  return result
}

enum HeaderCellFillColor {
  DEFAULT = '#cccccc',
  QUESTION = '#e0eaf3',
  SUB_HEADER = '#FFFFFF',
}

function buildTableHeaderCell({
  text,
  rowSpan = 1,
  columnSpan = 1,
  width,
  fillColor = HeaderCellFillColor.DEFAULT,
}: {
  text: string
  rowSpan?: number
  columnSpan?: number
  width?: ITableWidthProperties
  fillColor?: HeaderCellFillColor
}) {
  return new TableCell({
    columnSpan,
    rowSpan,
    width,
    children: [
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
          new TextRun({
            text,
            bold: true,
            size: 20,
          }),
        ],
        spacing: { before: 0 },
      }),
    ],
    margins: {
      bottom: 0,
      top: 0,
      left: 28,
      right: 28,
    },
    shading: {
      fill: fillColor,
    },
  })
}

function buildTableCell({
  children,
  rowSpan = 1,
  columnSpan = 1,
  width,
  shading,
}: {
  rowSpan?: number
  columnSpan?: number
  children: ParagraphChild[]
  width?: ITableWidthProperties
  shading?: IShadingAttributesProperties
}) {
  return new TableCell({
    columnSpan,
    rowSpan,
    children: [
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children,
        style: 'tableCell',
      }),
    ],
    shading,
    margins: {
      bottom: 0,
      top: 0,
      left: 28,
      right: 28,
    },
    width,
  })
}
function buildAttributeExtractionSection(
  review: Review,
  attributesStructure: AttributeStructure[],
  studies: ReviewItem[],
  studiesByType: Record<ReviewItemType, StudyEntry[]>,

  dataExtraction: any,
): ISectionOptions {
  let content: (Paragraph | Table)[] = []

  const cslStyle = review.project?.cslStyle
    ? review.project?.cslStyle
        .charAt(0)
        .toUpperCase()
        .replaceAll('-', ' ')
        .slice(1)
    : '-'
  if (attributesStructure.length > 0) {
    if (studies.length > 0) {
      attributesStructure.forEach((a) => {
        const headerRow = new TableRow({
          tableHeader: true,
          children: [
            buildTableHeaderCell({
              text: `Reference of ${cslStyle ?? '-'}`,
              width: { size: 25, type: WidthType.PERCENTAGE },
            }),
            buildTableHeaderCell({
              text: a.label,
              width: { size: 75, type: WidthType.PERCENTAGE },
            }),
          ],
        })

        const studyRows = studies.map((s) => {
          const desiredKey = `study::${s.id}|attribute::${a.id}`
          const studyReference =
            studiesByType[s.type].find((a) => a.studyId === s.id)?.inText ?? '-'
          const studyAttributeValue = dataExtraction[desiredKey]
            ? dataExtraction[desiredKey]
            : '-'
          return new TableRow({
            children: [
              buildTableCell({
                children: [
                  new TextRun({
                    text: studyReference,
                  }),
                ],
              }),
              new TableCell({
                children: [...convertHtmlToDocxParagraphs(studyAttributeValue)],
                margins: {
                  bottom: 0,
                  top: 0,
                  left: 28,
                  right: 28,
                },
              }),
            ],
          })
        })

        const synthesisTableIndex = tableIndex()
        tables.push(
          `Table ${synthesisTableIndex}: Data extraction for ${a.label}`,
        )

        const tableContent = [
          new Paragraph(
            `Table ${synthesisTableIndex} summarizes data extraction for ${a.label}.`,
          ),
          new Paragraph({
            children: [
              new Bookmark({
                id: `Table ${synthesisTableIndex}: Data extraction for ${a.label}`,
                children: [
                  new TextRun(
                    `Table ${synthesisTableIndex}: Data extraction for ${a.label}`,
                  ),
                ],
              }),
            ],
            style: 'Caption',
          }),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [headerRow, ...studyRows],
          }),
        ]

        content = content.concat(tableContent)
      })
    } else {
      content.push(new Paragraph({ text: 'No studies included.' }))
    }
  } else {
    content.push(new Paragraph({ text: 'No attributes found.' }))
  }
  return {
    properties: {
      page: {
        size: {
          orientation: PageOrientation.PORTRAIT,
        },
      },
    },
    children: [
      new Paragraph({
        text: 'Data extraction',
        heading: HeadingLevel.HEADING_1,
      }),
      ...content,
    ],
  }
}

function getInitialSections(data: {
  review: Review
  literatureSearchReportPlanText: string
  attributesStructure: AttributeStructure[]
  inclusionCriteria: InclusionCriteria
  citations: {
    findByStudyId: (studyId: Id) => { inText: string; fullText: string }
  }
  appraisalCriteria: AppraisalCriterion[]
  isPlanIncluded: boolean
  isReportIncluded: boolean
}): ISectionOptions {
  return {
    headers: {
      default: buildHeaderSection(
        data.review,
        data.literatureSearchReportPlanText,
      ),
    },
    properties: {
      type: SectionType.CONTINUOUS,
      page: {
        size: {
          orientation: PageOrientation.PORTRAIT,
        },
      },
    },
    children: [
      buildTitleSection(data.review, data.literatureSearchReportPlanText),
      ...buildApprovalSection(data.review),

      ...(data.isPlanIncluded
        ? [
            ...getReviewScopeAndMethodologySections(
              data.review,
              data.attributesStructure,
              data.inclusionCriteria,
            ),
            ...buildSearchTermsAndLimitsSection(
              data.review,
              false,
              data.citations,
            ),
            ...getAppraisalSections(data.review, data.appraisalCriteria),
          ]
        : []),

      ...(data.isReportIncluded
        ? [
            new Paragraph({
              text: 'Search results',
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              text: 'PRISMA 2020 diagram',
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph(
              'The PRISMA 2020 diagram on the following page summarizes the screening results.',
            ),
            new Paragraph({
              children: [new TextRun({})],
            }),
          ]
        : []),
    ],
  }
}

function getAppraisalSections(
  review: Review,
  appraisalCriteria: AppraisalCriterion[],
): (Paragraph | Table)[] {
  return [
    ...buildAppraisalStrategySection(review, appraisalCriteria),

    ...(review.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable
      ? buildOxfordLevelOfEvidenceSection(
          review.plan.appraisalPlan.isImdrfMdce2019Applicable,
        )
      : []),

    ...buildPeerAppraisalSection(review),
  ]
}

function getReviewScopeAndMethodologySections(
  review: Review,
  attributesStructure: AttributeStructure[],
  inclusionCriteria: InclusionCriteria,
): (Paragraph | Table)[] {
  return [
    ...buildScopeOfTheSystematicReviewSection(
      review.plan?.synthesisPlan.summary ?? '',
      attributesStructure,
    ),
    ...buildMethodologySection(review, inclusionCriteria),
  ]
}

function getFiguresAndTablesReferencesSections(): ISectionOptions {
  const children = [...buildTablesReferencesSection()]

  if (figures.length > 0) {
    children.push(...buildFiguresReferencesSection())
  }

  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },
    children,
  }
}
