<template>
  <div
    class="relative w-96 flex flex-col h-full overflow-hidden rounded-lg border border-slate-200"
  >
    <div
      class="px-4 py-3 bg-blue-800 text-white text-sm font-medium flex items-center gap-2"
    >
      <CircleStackIcon class="w-4" /> Sources
    </div>
    <div class="overflow-y-auto">
      <div class="divide-y">
        <SourcesPanel
          v-for="type in sourceTypes"
          :key="type"
          :source-type="type"
        />
        <ExpansionPanel
          class="w-full"
          :model-value="expansionPanel.isShown.value"
        >
          <template #activator>
            <button
              v-tooltip.right="otherSourcesTooltip"
              class="flex justify-between items-center w-full py-2 px-4"
              :class="buttonClass"
              @click="isPlanEditable ? togglePanel() : ''"
            >
              <div>Other source</div>
              <InformationCircleIcon class="w-4 h-4 text-primary" />
            </button>
          </template>
          <template #default>
            <NewImportSourceBuilder
              :import-source-type="ImportSourceType.OTHER_SOURCE"
            />
          </template>
        </ExpansionPanel>
        <button
          v-tooltip.right="citationSearchTooltip"
          class="px-4 py-2 w-full text-left flex items-center justify-between gap-2"
          :class="buttonClass"
          @click="isPlanEditable ? handleAddImportSource(citationSearch) : ''"
        >
          <div>Citation search</div>
          <InformationCircleIcon class="w-4 h-4 text-primary" />
        </button>
        <Modal ref="addCustomSource">
          <template #activator="{ show }">
            <button
              class="flex justify-between items-center w-full pl-4 py-[10px] pr-2 text-blue-800 text-sm font-semibold !border-2 rounded-md border-dashed border-blue-300 bg-blue-50 rounded-tl-none rounded-tr-none"
              @click="() => (isPlanEditable ? show() : null)"
            >
              <span class="flex gap-2 items-center">
                <PlusIcon class="text-blue-800" />
                <div>Custom source / Hand search</div>
              </span>

              <ArrowsPointingInIcon class="w-4 h-4 text-primary" />
            </button>
          </template>
          <template #content="{ hide }">
            <CustomSource @hide="hide" />
          </template>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import NewImportSourceBuilder from '../AddImportSourceToPlan/NewImportSourceBuilder.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { isUrlValid } from '@app/utils/urlValidation'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'

import useExpansionPanel from '@app/composables/use-expansion-panel'
import { CircleStackIcon } from '@heroicons/vue/24/solid'
import SourcesPanel from './SourcesPanel.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import PlusIcon from '@app/components/Icons/PlusIcon.vue'
import ArrowsPointingInIcon from '@app/components/Icons/ArrowsPointingInIcon.vue'
import CustomSource from '../Components/CustomSource.vue'

const expansionPanel = useExpansionPanel()

const sourceTypes = [
  'Cross-publisher',
  'Single-publisher',
  'Systematic reviews',
  'Clinical trials',
  'Incidents',
  'Safety information',
  'Standards',
  'Guidance',
]
const builtInImportSources = useBuiltInImportSources()
const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()

const citationSearchTooltip = `<p>Document records identified when screening the references of already identified records.</p>`

const citationSearch = builtInImportSources.findById(
  BuiltInImportSourceId.CITATION_SEARCH,
)!

const isPlanEditable = computed(() => !review.isPlanReadonly.value)

async function handleAddImportSource(data: {
  id: string
  name: string
  url?: string
  description?: string
  type: ImportSourceType
}) {
  if (
    data.type !== ImportSourceType.HAND_SEARCH &&
    data.type !== ImportSourceType.CITATION_SEARCH &&
    (!data.url || !isUrlValid(data.url))
  ) {
    throw new Error('Invalid URL')
  }
  loading.start()
  try {
    if (!isSourceUsedInReview(data.id)) {
      await review.addCustomImportSourceToPlan({
        id: data.id,
        name: data.name,
        url: data.url ?? '',
        description: data.description,
        type: data.type,
      })
      snackbar.show(
        SnackbarState.SUCCESS,
        'Import source successfully added to plan',
      )
    }
    document.getElementById(data.id)?.scrollIntoView({ behavior: 'smooth' })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

function isSourceUsedInReview(sourceId: string) {
  return review.entity.value.plan?.importPlan?.importSources?.some(
    (s) => s.id === sourceId,
  )
}

function togglePanel() {
  expansionPanel.toggle()
}

const otherSourcesTooltip = `
  <p>Other methods encompass sources where searches are not easily replicable, often due to varying search algorithms or personalized content delivery.</p><br/>
  <div class='flex gap-3'>
    <div>
      <div><strong>Search engine</strong></div>
      <div><strong>Health organizations</strong></div>
      <div><strong>Medical associations</strong></div>
      <div><strong>Standards Organizations</strong></div>
      <div><strong>Manufacturer websites</strong></div>
      <div><strong>Other online sources</strong></div>
    </div>
    <div>
      <div>EXAMPLES: Google Scholar, Google</div>
      <div>EXAMPLES: WHO, NICE</div>
      <div>EXAMPLES: European Society of Cardiology</div>
      <div>EXAMPLES: ISO, IEC</div>
      <div>EXAMPLES: Medtronix, Baxters</div>
      <div>Any online source not included above</div>
    </div>
  </div>
`

const buttonClass = computed(() => ({
  'hover:bg-black/5': isPlanEditable.value,
  'cursor-not-allowed': !isPlanEditable.value,
}))
</script>

<style scoped>
.tree {
  position: relative;
  padding-left: 20px;
}

.item {
  position: relative;
}

.sub-item {
  position: relative;
}

.sub-item::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: rgb(60 60 60 / 0.3);
}

.sub-item span::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 50%;
  width: 20px;
  height: 1px;
  background-color: rgb(60 60 60 / 0.3);
}

.sub-item:last-child::before {
  height: 50%;
}
</style>
