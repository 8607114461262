<template>
  <div v-if="sources.length > 0" class="border-b border-slate-200 pb-4">
    <button
      v-for="source in sources"
      :key="source.id"
      v-tooltip.right="getTooltip(source)"
      class="flex justify-between items-center w-full py-[10px] pl-7 pr-2.5 gap-2"
      :class="getButtonClass(source)"
      @click="handleClick(source)"
    >
      <div
        class="flex text-left items-center gap-2 text-slate-700 text-sm font-medium"
      >
        <img :src="source.iconUrl" class="w-6 h-6" alt="source icon" />
        <span class="line-clamp-1">{{ source.label }}</span>
      </div>
      <div
        v-if="source.isComingSoon"
        class="text-sm text-blue-800 bg-blue-100 border border-blue-800 rounded-full px-2"
      >
        Coming soon
      </div>
      <span
        v-if="source.isEvidenceMetadataExtractionTechnology"
        v-tooltip.right="'Evidence metadata extraction technology'"
      >
        <EvidenceLogo class="w-4 h-4" />
      </span>
    </button>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import EvidenceLogo from '@app/components/Icons/EvidenceLogo.vue'
import useWebExtension from '@app/composables/useWebExtension'

const { isExtensionInstalled } = useWebExtension()

const props = defineProps<{
  sources: {
    id: string
    label: string
    iconUrl: string
    isEvidenceMetadataExtractionTechnology: boolean
    url?: string
    description?: string
    type: string
    isComingSoon?: boolean
  }[]
  isPlanUnlocked: boolean
}>()
const emit = defineEmits(['addImportSource'])

const getButtonClass = (source: {
  id: string
  label: string
  iconUrl: string
  isEvidenceMetadataExtractionTechnology: boolean
  url?: string
  description?: string
  type: string
  isComingSoon?: boolean
}) => {
  return {
    'hover:bg-blue-50':
      (isExtensionInstalled.value ||
        !source.isEvidenceMetadataExtractionTechnology) &&
      props.isPlanUnlocked,
    'cursor-not-allowed': !props.isPlanUnlocked || source.isComingSoon,
  }
}

const getTooltip = (source: {
  isEvidenceMetadataExtractionTechnology: boolean
}) => {
  return !isExtensionInstalled.value &&
    source.isEvidenceMetadataExtractionTechnology
    ? 'Install the chrome extension to use this source'
    : ''
}

const handleClick = (source: {
  id: string
  label: string
  type: string
  url?: string
  description?: string
  isEvidenceMetadataExtractionTechnology: boolean
  isComingSoon?: boolean
}) => {
  if (props.isPlanUnlocked && !source.isComingSoon) {
    if (
      isExtensionInstalled.value ||
      !source.isEvidenceMetadataExtractionTechnology
    ) {
      emit('addImportSource', {
        id: source.id,
        name: source.label,
        type: source.type,
        url: source.url,
        description: source.description,
      })
    } else if (
      !isExtensionInstalled.value &&
      source.isEvidenceMetadataExtractionTechnology
    ) {
      window.open(
        'https://chromewebstore.google.com/detail/evidence-extension/bifaoaidegbcmjliabaeabnniphbaodi',
        '_blank',
        'noopener',
      )
    }
  }
}
</script>
