<template>
  <div
    v-if="currentModal === 'customSource'"
    class="w-screen max-w-[644px] p-6 relative pt-8"
  >
    <button class="absolute top-3 right-3" @click="emit('hide')">
      <XMarkIcon class="w-4 h-4 text-slate-400" />
    </button>
    <div class="space-y-4">
      <div class="flex gap-1 items-center">
        <DatabaseSolideIcon class="text-blue-800 w-5 h-5" />
        <div class="text-blue-800 font-semibold text-base">
          Custom source / Hand search
        </div>
      </div>
      <div class="space-y-6 pb-4">
        <div class="flex gap-2 items-center">
          <div class="group grid size-4">
            <input
              id="handsearch"
              v-model="isHandSearch"
              aria-describedby="handsearch-description"
              name="handsearch"
              type="checkbox"
              class="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-blue-800 checked:bg-blue-800 indeterminate:border-blue-800 indeterminate:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-800 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
            />
            <svg
              class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                class="opacity-0 group-has-[:checked]:opacity-100"
                d="M3 8L6 11L11 3.5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                class="opacity-0 group-has-[:indeterminate]:opacity-100"
                d="M3 7H11"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="text-sm/6">
            <label for="handsearch" class="font-medium text-slate-700 tetx-sm"
              >Use hand search instead</label
            >
          </div>
        </div>
        <TextInput
          placeholder="https://www.embase.com/"
          label="Source URL"
          :is-required="true"
          :disabled="isHandSearch"
          :class="{ 'opacity-50': isHandSearch }"
        />
      </div>
      <button
        class="w-full flex text-base gap-1 items-center justify-center bg-blue-800 text-white rounded-lg px-4 py-2 font-medium text-center"
        @click="handleButtonClick"
      >
        Next <ChevronRightBoldIcon class="w-5 h-5" />
      </button>
    </div>
  </div>

  <div v-else-if="currentModal === 'handSearch'">
    <HandSearch />
  </div>
</template>

<script setup lang="ts">
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import DatabaseSolideIcon from '@app/components/Icons/DatabaseSolideIcon.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import ChevronRightBoldIcon from '@app/components/Icons/ChevronRightBoldIcon.vue'
import { ref } from 'vue'
import HandSearch from './HandSearch.vue'

const emit = defineEmits<(e: 'hide') => void>()

const isHandSearch = ref(false)
const currentModal = ref<'customSource' | 'handSearch'>('customSource')

const handleButtonClick = () => {
  if (isHandSearch.value) {
    currentModal.value = 'handSearch'
  }
}
</script>
