<template>
  <div class="h-full">
    <div class="flex flex-1 pb-0 pr-0 gap-2 h-full">
      <div class="flex gap-4 w-full relative h-full">
        <div class="w-full relative">
          <AttributeTextField
            :id="attributeStructure.id"
            v-model:html="draftInHtml"
            :placeholder="attributeStructure.label"
            :is-expanded="isExpanded"
            :is-review-readonly="review.isReviewReadonly.value"
            :study="study"
          />
          <div class="absolute top-2 right-2">
            <StudyQA
              v-if="!review.isReviewReadonly.value"
              v-model="attributeValue"
              :study="study"
              :attribute-structure="attributeStructure"
              :query="attributeStructure.question"
              :is-review-readonly="review.isReviewReadonly.value"
              @save="updateAttributeValue"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import AttributeTextField from './AttributeTextField.vue'
import StudyQA from './StudyQA.vue'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
const props = withDefaults(
  defineProps<{
    attributeStructure: AttributeStructure
    study: ReviewItem
    isExpanded: boolean
    isReviewReadOnly?: boolean
  }>(),
  {
    isReviewReadOnly: false,
  },
)

const review = injectStrict(ReviewKey)
const draftInHtml = ref('')
const attributeValue = ref(
  props.study.synthesis.attributes.find(
    (a) => a.attributeStructureId === props.attributeStructure.id,
  )?.value ?? '',
)

async function updateAttributeValue(value: string) {
  draftInHtml.value = value
}
</script>

<style>
.loader {
  border: 4px solid var(--color-primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
