<template>
  <ImportSourceCard
    :import-source="handSearch"
    :tooltip="{
      text: 'perform a hand search and manually import studies.',
      url: '/docs/reviews.html#hand-search',
    }"
    @import-source-removed="emit('importSourceRemoved')"
  >
  </ImportSourceCard>
</template>
<script setup lang="ts">
import ImportSourceCard from '../Components/ImportSourceCard.vue'

import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'

const builtInImportSources = useBuiltInImportSources()
const emit = defineEmits<(e: 'importSourceRemoved') => void>()

const handSearch = builtInImportSources.findById(
  BuiltInImportSourceId.HAND_SEARCH,
)!
</script>
