import { AlignmentType, Paragraph, TextRun } from 'docx'

export function convertHtmlToDocxParagraphs(json: any): Paragraph[] {
  const paragraphs: Paragraph[] = []

  json.content?.forEach((node: any, index: number) => {
    if (isListNode(node)) {
      processListNode(node, paragraphs, index)
    } else if (node.type === 'paragraph') {
      processParagraphNode(node, paragraphs)
    }
  })

  return paragraphs
}

function isListNode(node: any): boolean {
  return node.type === 'bulletList' || node.type === 'orderedList'
}

function processListNode(node: any, paragraphs: Paragraph[], index: number) {
  node.content?.forEach((listItem: any) => {
    const paragraphNode = findParagraphNode(listItem)
    if (paragraphNode?.content) {
      const textRuns = createTextRuns(paragraphNode.content)
      paragraphs.push(
        new Paragraph({
          children: textRuns,
          bullet: node.type === 'bulletList' ? { level: 0 } : undefined,
          numbering:
            node.type === 'orderedList'
              ? { reference: 'orderedNumbering', level: 0, instance: index }
              : undefined,
          style: 'tableCell',
          alignment: AlignmentType.LEFT,
        }),
      )
    }
  })
}

function findParagraphNode(listItem: any): any {
  return listItem.content?.find((child: any) => child.type === 'paragraph')
}

function processParagraphNode(node: any, paragraphs: Paragraph[]) {
  if (node.content) {
    const textRuns = createTextRuns(node.content)
    paragraphs.push(
      new Paragraph({
        children: textRuns,
        style: 'tableCell',
        alignment: AlignmentType.LEFT,
      }),
    )
  }
}

function createTextRuns(content: any[]): TextRun[] {
  return content.map((child: any) => {
    const text = child.text || ''
    const isBold = child.marks?.some((mark: any) => mark.type === 'bold')
    const isItalic = child.marks?.some((mark: any) => mark.type === 'italic')
    const isStrikethrough = child.marks?.some(
      (mark: any) => mark.type === 'strike',
    )
    const isUnderline = child.marks?.some(
      (mark: any) => mark.type === 'underline',
    )

    return new TextRun({
      text,
      bold: isBold || false,
      italics: isItalic || false,
      strike: isStrikethrough || false,
      underline: isUnderline ? {} : undefined,
    })
  })
}
