<template>
  <div class="p-6 space-y-6 container w-[751px]">
    <div class="mb-5 text-blue-700 font-semibold tracking-[-0.14px] text-base">
      Assign evaluators and roles
    </div>
    <div>
      <div
        v-if="
          review.entity.value.evaluators.length > 0 || shouldShowCreateEvaluator
        "
      >
        <div class="w-full border-collapse border-spacing-0">
          <div class="grid grid-cols-[2fr_2fr_auto] font-medium text-sm pb-2">
            <div>Role</div>
            <div>Name</div>
            <div class="w-6"></div>
          </div>
          <div>
            <template
              v-for="evaluator in review.entity.value.evaluators"
              :key="evaluator.id"
            >
              <EvaluatorComponent :evaluator="evaluator" />
            </template>
            <CreateEvaluator
              v-if="shouldShowCreateEvaluator"
              @hide="shouldShowCreateEvaluator = false"
            />
          </div>
        </div>
      </div>
      <EvaluatorPlaceHolder
        v-else-if="!shouldShowCreateEvaluator"
        class="w-[calc(100%-25px)]"
      />
      <button
        v-if="!review.isPlanReadonly.value"
        :disabled="shouldShowCreateEvaluator"
        class="w-[calc(100%-25px)] mt-8 border-2 border-dashed border-blue-300 bg-blue-50 rounded-md text-blue-700 text-sm font-medium px-5 py-3"
        :class="
          shouldShowCreateEvaluator ? 'opacity-40 hover:cursor-not-allowed' : ''
        "
        @click="shouldShowCreateEvaluator = true"
      >
        + Add evaluator
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import EvaluatorComponent from './Evaluator.vue'
import CreateEvaluator from './CreateEvaluator.vue'
import { ref } from 'vue'
import EvaluatorPlaceHolder from '@app/components/Icons/EvaluatorPlaceHolder.vue'

const review = injectStrict(ReviewKey)
const shouldShowCreateEvaluator = ref(false)
</script>
