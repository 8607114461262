<template>
  <div>
    <ArticleDetails
      v-if="article"
      :article="article"
      :is-metadata-expanded="props.isMetadataExpanded"
      :expansion-panel="expansionPanel"
    />
    <IncidentDetails
      v-else-if="incident"
      :incident="incident"
      :expansion-panel="expansionPanel"
    />
    <GuidanceDetails
      v-else-if="guidance"
      :guidance="guidance"
      :expansion-panel="expansionPanel"
    />
    <RegistrationDetails
      v-else-if="registration"
      :registration="registration"
      :expansion-panel="expansionPanel"
    />
    <FieldSafetyNoticeDetails
      v-else-if="fsn"
      :fsn="fsn"
      :expansion-panel="expansionPanel"
    />
  </div>
</template>
<script setup lang="ts">
import ArticleDetails from './Article.vue'
import IncidentDetails from './Incident.vue'
import GuidanceDetails from './Guidance.vue'

import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import RegistrationDetails from './Registration.vue'
import FieldSafetyNoticeDetails from './FieldSafetyNotice.vue'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { computed } from 'vue'
import { Article } from '@core/domain/models/article.model'
import { Incident } from '@core/domain/models/incident.model'
import { Guidance } from '@core/domain/models/guidance.model'
import { Registration } from '@core/domain/models/registration.model'
import { FieldSafetyNotice } from '@core/domain/models/fieldSafetyNotice.model'

const props = defineProps<{
  reviewItem: ReviewItem
  expansionPanel: any
  isMetadataExpanded: boolean
}>()

const article = computed(() => {
  if (props.reviewItem.type === ReviewItemType.Article) {
    return props.reviewItem as Article
  }
  return null
})

const incident = computed(() => {
  if (props.reviewItem.type === ReviewItemType.Incident) {
    return props.reviewItem as Incident
  }
  return null
})

const guidance = computed(() => {
  if (props.reviewItem.type === ReviewItemType.Guidance) {
    return props.reviewItem as Guidance
  }
  return null
})

const registration = computed(() => {
  if (props.reviewItem.type === ReviewItemType.Registration) {
    return props.reviewItem as Registration
  }
  return null
})

const fsn = computed(() => {
  if (props.reviewItem.type === ReviewItemType.FieldSafetyNotice) {
    return props.reviewItem as FieldSafetyNotice
  }
  return null
})
</script>
