<template>
  <div
    class="w-[980px] create-review-modal flex flex-col bg-slate-100 rounded-2xl relative"
  >
    <div
      class="flex px-6 py-4 justify-between items-center border-b border-200 shadow-sm"
    >
      <div class="flex gap-2 text-blue-800">
        <SafetyInformationIcon />
        <p class="font-semibold">Safety Information</p>
      </div>
      <button class="absolute top-4 right-6" @click="emit('hide')">
        <XMarkIcon class="w-4 h-4 text-slate-400" />
      </button>
    </div>

    <div class="px-6 pt-4">
      <TextInput
        v-model="searchQuery"
        placeholder="Search in all supported satefy information databases."
        class="w-full"
        :input-classes="'px-3 py-1'"
        @enter="onEnter"
      />
    </div>
    <div
      class="flex flex-col flex-1 h-full p-6 items-start gap-4 overflow-auto"
    >
      <div class="w-full flex-1 relative h-full">
        <div
          v-if="isLoading"
          class="overflow-hidden absolute top-0 left-0 w-full h-1"
        >
          <div class="line absolute bg-primary/20 h-1"></div>
          <div class="absolute bg-primary h-1 inc"></div>
          <div class="absolute bg-primary h-1 dec"></div>
        </div>
        <ul v-if="results.length" class="space-y-2">
          <li
            v-for="article in results"
            :key="article.id"
            class="flex items-center self-stretch rounded border border-slate-300 bg-white hover:bg-slate-200 transition"
          >
            <div v-if="isLoading" class="p-3">Loading</div>
            <template v-else>
              <component
                :is="isUrlValid(article.link) ? 'a' : 'div'"
                v-bind="getLinkAttributes(article.link)"
                class="flex flex-1 justify-between px-3 py-2"
              >
                <div class="flex items-center gap-3 flex-1">
                  <component
                    :is="fsnSources.find((s) => s.id === article.source)?.icon"
                    class="rounded-full w-4 h-4"
                  />
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p
                    class="text-slate-700 font-medium text-sm line-clamp-1 w-4/5"
                    v-html="article.title"
                  />
                </div>
                <div class="flex items-center gap-2">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p
                    class="text-slate-400 font-inter text-sm font-normal leading-5 uppercase"
                    v-html="article.source"
                  />
                  <div class="w-[1px] h-[12px] bg-slate-400"></div>
                  <p
                    class="text-slate-400 font-inter text-sm font-normal leading-5"
                    v-html="article.type"
                  />
                </div>
              </component>
            </template>
          </li>
        </ul>
        <div v-else-if="totalResults === 0">No results</div>
        <div v-else></div>
      </div>
    </div>
    <div
      v-if="results.length"
      class="p-6 flex justify-between items-center w-full"
    >
      <div v-if="totalPages > 1" class="flex p-5 px-0 items-center gap-4">
        <button
          :disabled="currentPage === 1 || isLoading"
          class="flex py-1.5 px-3 justify-center items-center gap-1 rounded-[4px] bg-white text-slate-700 border border-slate-300 font-medium shadow text-sm"
          :class="{
            'bg-slate-100 !text-[#334155]': currentPage === 1,
          }"
          @click="prevPage"
        >
          <span class="w-4 h-4 rotate-180">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.83588 11.7657C5.52346 11.4533 5.52346 10.9467 5.83588 10.6343L8.47019 8L5.83588 5.36569C5.52346 5.05327 5.52346 4.54673 5.83588 4.23431C6.1483 3.9219 6.65483 3.9219 6.96725 4.23431L10.1672 7.43431C10.4797 7.74673 10.4797 8.25327 10.1672 8.56569L6.96725 11.7657C6.65483 12.0781 6.1483 12.0781 5.83588 11.7657Z"
                :fill="currentPage === 1 ? '#334155' : '#334155'"
              />
            </svg>
          </span>
          Previous
        </button>
        <span
          class="text-slate-600 font-circular text-xs font-medium leading-5"
        >
          Page {{ currentPage }} of {{ totalPages }}
        </span>
        <button
          :disabled="currentPage === totalPages || isLoading"
          class="flex py-1.5 px-3 justify-center items-center gap-1 rounded-[4px] bg-white text-slate-700 border border-slate-300 font-medium shadow text-sm"
          :class="{
            'bg-slate-100 !text-[#334155]': currentPage === totalPages,
          }"
          @click="nextPage"
        >
          Next
          <span class="w-4 h-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.83588 11.7657C5.52346 11.4533 5.52346 10.9467 5.83588 10.6343L8.47019 8L5.83588 5.36569C5.52346 5.05327 5.52346 4.54673 5.83588 4.23431C6.1483 3.9219 6.65483 3.9219 6.96725 4.23431L10.1672 7.43431C10.4797 7.74673 10.4797 8.25327 10.1672 8.56569L6.96725 11.7657C6.65483 12.0781 6.1483 12.0781 5.83588 11.7657Z"
                :fill="currentPage === totalPages ? '#334155' : '#334155'"
              />
            </svg>
          </span>
        </button>
      </div>
      <div>
        <button
          class="truncate flex flex-row gap-2 bg-blue-800 text-white items-center py-2 px-4 rounded-md text-base font-medium"
          :class="{ '!bg-slate-100 !text-black/50': !canImport }"
          :disabled="!canImport || isImporting || isLoading"
          @click="importSearch"
        >
          <ImportIcon v-if="!isImporting" class="w-5 h-5 text-white" />
          <span v-if="isImporting"> <Spinner color="white" /> </span>
          <span v-else
            >Import {{ totalResults > -1 ? totalResults : 0 }} results</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { computed, ref } from 'vue'
import { SnackbarState } from '@app/types'
import useSnackbar from '@app/composables/use-snackbar'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import GermanyFlag from '@app/components/Icons/flags/Germany.vue'
import FranceFlag from '@app/components/Icons/flags/France.vue'
import UKFlag from '@app/components/Icons/flags/UK.vue'
import AustraliaFlag from '@app/components/Icons/flags/Australia.vue'
import USFlag from '@app/components/Icons/flags/USA.vue'
import CyprusFlag from '@app/components/Icons/flags/Cyprus.vue'
import Spinner from '@app/components/Global/Spinner.vue'
import { isUrlValid } from '@app/utils/urlValidation'

import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import axios from 'axios'
import SafetyInformationIcon from '@app/components/Icons/SafetyInformationIcon.vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import ImportIcon from '@app/components/Icons/ImportIcon.vue'

type FieldSafetyNotice = {
  id: string
  title: string
  date: string
  link: string
  summary: string
  source: string
  fulltext?: string
  type: string
  pdfLinks?: string | string[]
}

const review = injectStrict(ReviewKey)
const snackbar = useSnackbar()

const emit = defineEmits<(e: 'hide') => void>()

const searchQuery = ref('')
const results = ref<FieldSafetyNotice[]>([])
const currentPage = ref(1)
const totalPages = ref(1)
const limit = ref(12)
const totalResults = ref(-1)

const isLoading = ref(false)
const isImporting = ref(false)

const fsnSources = [
  {
    id: 'bfarm',
    name: 'BfArM',
    icon: GermanyFlag,
  },
  {
    id: 'ansm',
    name: 'ANSM',
    icon: FranceFlag,
  },
  {
    id: 'sara',
    name: 'SARA',
    icon: AustraliaFlag,
  },
  {
    id: 'mhra',
    name: 'MHRA',
    icon: UKFlag,
  },
  {
    id: 'fda',
    name: 'FDA',
    icon: USFlag,
  },
  {
    id: 'moh',
    name: 'ΙΥ&ΥΔΥ',
    icon: CyprusFlag,
  },
]

const canImport = computed(() => {
  return (
    review.entity.value?.plan?.lockState === ReviewLockState.UNLOCKED &&
    review.entity.value.searches
      ?.filter(
        (s) => s.source.id === BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
      )
      ?.every((search) => search.searchQuery !== searchQuery.value) &&
    searchQuery.value !== ''
  )
})

async function addImportSourceToPlan(importSourceId: string) {
  try {
    const dataSource = review.entity.value.plan?.importPlan.importSources?.find(
      (source) => source.id === importSourceId,
    )
    if (!dataSource) {
      await review.addCustomImportSourceToPlan({
        name: 'Safety information',
        url: 'https://evidence.systems',
        description: '',
        type: ImportSourceType.FIELD_SAFETY_NOTICES,
        id: BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
      })
    }
  } catch (error) {
    console.error('Add source to plan failed: ', error)
  }
}

async function importSearch() {
  isImporting.value = true
  try {
    await searchForImport()
    const date = new Date().toISOString()
    await addImportSourceToPlan(BuiltInImportSourceId.FIELD_SAFETY_NOTICES)

    const items =
      results.value.map((result) => ({
        fsnId: result.id,
        title: result.title,
        date: result.date,
        link: result.link,
        summary: result.summary,
        source: result.source,
        fulltext: result.fulltext,
        fsnType: result.type,
        type: ReviewItemType.FieldSafetyNotice,
        potentialPdfUrl: Array.isArray(result.pdfLinks)
          ? result.pdfLinks[0]
          : result.pdfLinks,
      })) ?? []

    await review.importSearch({
      query: searchQuery.value,
      date,
      filters: '',
      importSourceId: BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
      items,
    })

    snackbar.show(SnackbarState.SUCCESS, 'import successful')
  } catch (e) {
    console.error('Import failed:', e)
    snackbar.show(SnackbarState.ERROR, 'import failed')
  } finally {
    isImporting.value = false
  }
}

async function performSearch(pagination = { limit: 5000, offset: 0 }) {
  if (!searchQuery.value) {
    return
  }
  const host = location.protocol + '//' + location.host + '/fsn-api'
  const solrUrl = `${host}/select?q=title:(${encodeURIComponent(searchQuery.value)}) OR summary:(${encodeURIComponent(searchQuery.value)}) OR fulltext:(${encodeURIComponent(searchQuery.value)})&start=${pagination.offset}&rows=${pagination.limit}&wt=json`

  try {
    const response = await axios.get(solrUrl)
    const searchResults = response.data.response

    if (searchResults?.docs) {
      results.value = searchResults.docs.map((doc: any) => ({
        id: doc.id,
        title: doc.title?.[0],
        date: doc.date?.[0],
        link: doc.link?.[0],
        summary: doc.summary?.[0],
        source: doc.source?.[0],
        fulltext: doc.fulltext?.[0],
        type: doc.type?.[0],
      }))
      totalResults.value = searchResults.numFound
      totalPages.value = Math.ceil(totalResults.value / limit.value)
    } else {
      results.value = []
      totalResults.value = 0
      totalPages.value = 1
    }
  } catch (error) {
    console.error('Error searching documents:', error)
    results.value = []
    totalResults.value = 0
    totalPages.value = 1
  }
}

async function search(pagination = { limit: 5000, offset: 0 }) {
  isLoading.value = true
  try {
    await performSearch(pagination)
  } catch (error) {
    console.error('Error searching documents:', error)
  } finally {
    isLoading.value = false
  }
}

async function searchForImport() {
  try {
    await performSearch()
  } catch (error) {
    console.error('Error searching documents:', error)
  }
}

function onEnter() {
  reset()
  search({ limit: limit.value, offset: (currentPage.value - 1) * limit.value })
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1
    search({
      limit: limit.value,
      offset: (currentPage.value - 1) * limit.value,
    })
  }
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value -= 1
    search({
      limit: limit.value,
      offset: (currentPage.value - 1) * limit.value,
    })
  }
}

function reset() {
  results.value = []
  currentPage.value = 1
  totalPages.value = 1
  totalResults.value = -1
}

const getLinkAttributes = (link: string) => {
  return isUrlValid(link) ? { href: link, target: '_blank' } : {}
}
</script>
