<template>
  <div class="relative h-full w-full">
    <div class="flex flex-col overflow-hidden">
      <TransitionGroup name="flash">
        <div
          v-for="importSource in importSources"
          :key="importSource?.id"
          class="break-inside-avoid rounded-md"
        >
          <ImportSourceCard
            v-if="
              importSource?.id &&
              isBuiltInImportSource(importSource.id) &&
              ![
                BuiltInImportSourceId.HAND_SEARCH,
                BuiltInImportSourceId.CITATION_SEARCH,
              ].includes(importSource.id as BuiltInImportSourceId)
            "
            :import-source="importSource"
            :icon-url="importSource.iconUrl"
            :tooltip="{
              text: `use the Evidence browser extension to import studies from ${importSource.name}`,
              url: `/docs/reviews.html#${importSource.id}`,
            }"
            :query="generateSearchQuery(importSource, review.entity.value)"
          />

          <ImportFromCustomSource
            v-else-if="
              ![
                BuiltInImportSourceId.HAND_SEARCH,
                BuiltInImportSourceId.CITATION_SEARCH,
              ].includes(importSource.id as BuiltInImportSourceId)
            "
            :import-source="importSource"
          />
        </div>
        <CitationSearch
          v-if="
            review.entity.value.plan?.importPlan.importSources?.some(
              (i) => i.id === BuiltInImportSourceId.CITATION_SEARCH,
            )
          "
          key="citation-search"
        />
        <HandSearch
          v-if="
            review.entity.value.plan?.importPlan.importSources?.some(
              (i) => i.id === BuiltInImportSourceId.HAND_SEARCH,
            )
          "
          key="hand-search"
        />
      </TransitionGroup>
    </div>
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { generateSearchQuery } from '@app/utils/generateSearchQuery'
import { ReviewKey } from '../../../use-review'
import { computed } from 'vue'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import ImportFromCustomSource from '../CustomImportSource/ImportFromCustomSource.vue'
import HandSearch from '../HandSearch/HandSearch.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import ImportSourceCard from '../Components/ImportSourceCard.vue'
import CitationSearch from '../CitationSearch/CitationSearch.vue'

const review = injectStrict(ReviewKey)
const builtInImportSources = useBuiltInImportSources()

const isBuiltInImportSource = (importSourceId: string) => {
  return !!builtInImportSources.findById(importSourceId)
}

const importSources = computed(() => {
  return [...(review.entity.value.plan?.importPlan.importSources ?? [])]
    .map((importSource) => {
      return {
        ...importSource,
        iconUrl: builtInImportSources.findById(importSource.id)?.iconUrl,
      }
    })
    .sort((a, b) => {
      const aLength = review.searchesBySource.value?.[a.id]?.length || 0
      const bLength = review.searchesBySource.value?.[b.id]?.length || 0

      if (aLength > 0 && bLength === 0) {
        return -1
      } else if (aLength === 0 && bLength > 0) {
        return 1
      } else {
        return 0
      }
    })
})
</script>
